import React from "react";
import { runInAction } from "mobx";
import { Box } from "@/shared/components";
import { PageHeader } from "@/shared/components/PageHeader";
import { useStoreContext } from "@/shared/hooks/useStore";
import { ReceiveCryptoStep, ReceiveCryptoStore, } from "@/pages/ReceiveCryptoPage/stores/ReceiveCryptoStore";
import { AddFunds } from "@/shared/components/AddFunds";
export var GetAddress = function () {
    var _a;
    var receiveCrypto = useStoreContext(ReceiveCryptoStore);
    var onBack = function () {
        runInAction(function () {
            receiveCrypto.step = ReceiveCryptoStep.ChooseCrypto;
        });
    };
    return (React.createElement(Box, null,
        React.createElement(PageHeader, { title: "Receive ".concat((_a = receiveCrypto.currency) === null || _a === void 0 ? void 0 : _a.shortName), onBack: onBack }),
        receiveCrypto.currency && receiveCrypto.blockchain && (React.createElement(AddFunds, { selectedCurrency: receiveCrypto.currency, selectedBlockchain: receiveCrypto.blockchain }))));
};
