import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import React from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { CreateAccountPopup } from "@/features/create-account/components/CreateAccountPopup";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export var Accounts = observer(function () {
    var popup = useToggler();
    var _a = useIntersectionObserver({
        threshold: 1,
    }), lastItemIsIntersecting = _a.isIntersecting, lastItem = _a.ref;
    var firstItemIsIntersecting = useIntersectionObserver({
        threshold: 1,
    }).isIntersecting;
    var balanceList = useStoreContext(BalanceListStore);
    var accounts = useStoreContext(AccountListStore);
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.fiat.map(function (acc) { return acc.account; }));
    }, [balanceList, accounts.fiat]);
    if (accounts.list.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledBox, { "$isRightHidden": !lastItemIsIntersecting && !!accounts.fiat.length, "$isLeftHidden": !firstItemIsIntersecting && !!accounts.fiat.length },
            React.createElement(Box, { padding: "30px 20px", height: "250px", boxSizing: "border-box", bgColor: "general-white", borderRadius: "22px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "20px", width: "280px", ref: lastItem, tabIndex: 0, role: "button", onClick: function () { return popup.on(); } },
                React.createElement(BaseIcon, { icon: "Plus", size: 42, padding: "14px", borderRadius: "100%", bgColor: "light-blue", color: "deep-blue-500" }),
                React.createElement(Typography, { color: "deep-blue-500" }, "Open new bank account"))),
        popup.enabled && (React.createElement(CreateAccountPopup, { onClose: popup.off, onSuccess: function () {
                accounts.sync();
                popup.off();
            } }))));
});
var StyledBox = styled("div")(function (_a) {
    var $isRightHidden = _a.$isRightHidden, $isLeftHidden = _a.$isLeftHidden;
    return ({
        position: "relative",
        "& .swiper-slide": {
            width: "280px",
        },
        "&:after": {
            transition: "all 0.3s ease",
            opacity: $isRightHidden ? 1 : 0,
            content: '""',
            width: "100px",
            height: "300px",
            position: "absolute",
            right: "0",
            top: "50%",
            zIndex: 1,
            transform: "translateY(-50%)",
            background: "linear-gradient(270deg, #F6F7F8 0%, rgba(246, 247, 248, 0) 100%)",
            pointerEvents: "none",
        },
        "&:before": {
            transition: "all 0.3s ease",
            content: '""',
            opacity: $isLeftHidden ? 1 : 0,
            width: "100px",
            height: "300px",
            position: "absolute",
            left: "0",
            top: "50%",
            zIndex: 2,
            transform: "translateY(-50%)",
            background: "linear-gradient(90deg, #F6F7F8 0%, rgba(246, 247, 248, 0) 100%)",
            pointerEvents: "none",
        },
    });
});
