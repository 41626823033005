var _a;
import { BalanceLogReasonType } from "./types";
export var TEXT_TYPE = (_a = {},
    _a[BalanceLogReasonType.ORDER] = "Transfer",
    _a[BalanceLogReasonType.BTC_DEPOSIT] = "Deposit",
    _a[BalanceLogReasonType.ETH_DEPOSIT] = "Deposit",
    _a[BalanceLogReasonType.TON_DEPOSIT] = "Deposit",
    _a[BalanceLogReasonType.BTC_WITHDRAWAL_REQUEST] = "Withdrawal",
    _a[BalanceLogReasonType.BTC_WITHDRAWAL_REJECT] = "Withdrawal (Return payment)",
    _a[BalanceLogReasonType.BTC_WITHDRAWAL_FAIL] = "Withdrawal (Return payment)",
    _a[BalanceLogReasonType.ETH_WITHDRAWAL_REQUEST] = "Withdrawal",
    _a[BalanceLogReasonType.ETH_WITHDRAWAL_REJECT] = "Withdrawal (Return payment)",
    _a[BalanceLogReasonType.ETH_WITHDRAWAL_FAIL] = "Withdrawal (Return payment)",
    _a[BalanceLogReasonType.TON_WITHDRAWAL_REQUEST] = "Withdrawal",
    _a[BalanceLogReasonType.TON_WITHDRAWAL_REJECT] = "Withdrawal (Return payment)",
    _a[BalanceLogReasonType.TON_WITHDRAWAL_FAIL] = "Withdrawal (Return payment)",
    _a[BalanceLogReasonType.FIAT_WITHDRAWAL_REQUEST] = "Withdrawal",
    _a[BalanceLogReasonType.FIAT_WITHDRAWAL_REJECT] = "Withdrawal (Return payment)",
    _a[BalanceLogReasonType.DEPOSIT_FEE] = "Deposit",
    _a[BalanceLogReasonType.WITHDRAWAL_FEE] = "Withdrawal fee",
    _a[BalanceLogReasonType.REJECTED_WITHDRAWAL_FEE] = "Withdrawal fee (Return payment)",
    _a[BalanceLogReasonType.FAILED_WITHDRAWAL_FEE] = "Withdrawal fee (Return payment)",
    _a[BalanceLogReasonType.ORDER_FEE] = "Transfer",
    _a[BalanceLogReasonType.DIRECT_EXCHANGE_FEE] = "Exchange",
    _a[BalanceLogReasonType.LOCK_BALANCE] = "Transfer",
    _a[BalanceLogReasonType.UNLOCK_BALANCE] = "Transfer",
    _a[BalanceLogReasonType.UNKNOWN] = "Transfer",
    _a[BalanceLogReasonType.TRANSFER] = "Transfer",
    _a[BalanceLogReasonType.DEPOSIT] = "Deposit",
    _a[BalanceLogReasonType.WITHDRAWAL] = "Withdrawal",
    _a[BalanceLogReasonType.DIRECT_EXCHANGE] = "Exchange",
    _a);
