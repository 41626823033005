import React from "react";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
export var SubscriptionCard = function (_a) {
    var options = _a.options, title = _a.title, price = _a.price, fee = _a.fee, onSelect = _a.onSelect, _b = _a.theme, theme = _b === void 0 ? "white" : _b, disabled = _a.disabled;
    return (React.createElement(Box, { padding: "30px", display: "flex", flexDirection: "column", gap: "40px", bgColor: theme === "black" ? "black-1200" : "general-white", borderRadius: "22px" },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
            React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Typography, { type: "text24Bold", color: theme === "black" ? "general-white" : "black-1200" }, title)),
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                React.createElement(Typography, { type: "text36Bold", color: theme === "black" ? "general-white" : "black-1200" },
                    "$",
                    fee),
                React.createElement(Typography, { color: theme === "black" ? "general-white" : "black-1200" }, "one-time fee")),
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                React.createElement(Typography, { type: "text36Bold", color: theme === "black" ? "general-white" : "black-1200" },
                    "$",
                    price),
                React.createElement(Typography, { color: theme === "black" ? "general-white" : "black-1200" }, "monthly subscription"),
                React.createElement(Typography, { color: "general-green" }, "First month FREE"))),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
            React.createElement(Button, { disabled: disabled, size: "s", variant: theme === "black" ? "White" : "Black", onClick: onSelect }, "Select plan"),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" }, options.map(function (option, index) { return (
            // eslint-disable-next-line react/no-array-index-key
            React.createElement(Box, { display: "flex", alignItems: "center", gap: "6px", key: index },
                React.createElement(BaseIcon, { icon: "Check", color: "black-500", size: 8 }),
                React.createElement(Typography, { color: "black-500", type: "text14" }, option))); })))));
};
