import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { PhoneInput } from "@/shared/components/FormControlInput/PhoneInput";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
export var PhoneForm = observer(function () {
    var _a;
    var page = useStoreContext(SignupPageStore);
    return (React.createElement(AuthForm, { onSubmit: page.submitPhone, title: "Your phone number", content: React.createElement(PhoneInput, { value: page.phone, error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, disabled: page.loading, onChange: action(function (e) {
                page.phone = e;
            }) }), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: page.loading || !page.isPhoneValid }, "Continue") }));
});
