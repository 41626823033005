import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { AuthPageStore } from "@/pages/AuthPage/stores/AuthPageStore";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
import { ROUTES } from "@/routes";
import { FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { Link } from "@/shared/components/Link";
import { PasswordHint } from "@/shared/components/PasswordHint";
import { useMedia } from "@/shared/hooks/useMedia";
import { onChange } from "@/shared/utils/input";
import { useStoreContext } from "@/shared/hooks/useStore";
export var PasswordForm = observer(function () {
    var _a, _b;
    var page = useStoreContext(SignupPageStore);
    var auth = useStoreContext(AuthPageStore);
    var media = useMedia();
    return (React.createElement(AuthForm, { onBack: media.tablet ? auth.toggleForm : undefined, onSubmit: page.submitPassword, title: "Create an account", desc: React.createElement(React.Fragment, null,
            "Already have?",
            " ",
            React.createElement(Link, { type: "text16Bold", to: generatePath(ROUTES.signin) }, "Sign in")), content: React.createElement(React.Fragment, null,
            React.createElement(FormControlInput, { type: "password", label: "Password", value: page.password, onChange: onChange(action(function (value) {
                    page.password = value;
                })), variantError: "alert", error: (_a = page.errorsByKey.password) === null || _a === void 0 ? void 0 : _a[0], disabled: page.loading }),
            React.createElement(PasswordHint, { digit: page.passwordValidation.digit, length: page.passwordValidation.length, lowercase: page.passwordValidation.lowercase, special: page.passwordValidation.special, uppercase: page.passwordValidation.uppercase }),
            React.createElement(Button, { type: "submit", width: "100%", sx: { borderRadius: "16px" }, error: (_b = page.errorsByKey.unknown) === null || _b === void 0 ? void 0 : _b[0], disabled: !page.isSignupFormValid || page.loading }, "Continue")) }));
});
