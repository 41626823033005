import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { OrderCardStore } from "@/pages/CardPage/store/OrderCardStore";
export var PickCardColor = observer(function (_a) {
    var designs = _a.designs;
    if (!designs) {
        return null;
    }
    var orderCardStore = useStoreContext(OrderCardStore);
    useEffect(function () {
        if (designs.length > 0 && !orderCardStore.cardDesignId) {
            orderCardStore.setCardDesignId(designs[0].id);
        }
    }, [designs, orderCardStore]);
    var handleColorChange = function (id) {
        orderCardStore.setCardDesignId(id);
    };
    return (React.createElement(Box, null,
        React.createElement(Typography, { type: "text16", fontWeight: 700 }, "Pick your color"),
        React.createElement(ColorPickerContainer, null, designs.map(function (design) { return (React.createElement(ColorBox, { key: design.id, color: "blue", selected: orderCardStore.cardDesignId === design.id, onClick: function () { return handleColorChange(design.id); } })); }))));
});
var ColorPickerContainer = styled(Box)({
    display: "flex",
    gap: "28px",
    marginTop: "16px",
    marginLeft: "8px",
});
var ColorBox = styled(Box, {
    shouldForwardProp: function (prop) { return prop !== "color" && prop !== "selected"; },
})(function (_a) {
    var color = _a.color, selected = _a.selected;
    return ({
        position: "relative",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        backgroundColor: color,
        cursor: "pointer",
        transition: "all 0.3s ease",
        "&::before": {
            content: '""',
            position: "absolute",
            top: "-8px",
            left: "-8px",
            width: "42px",
            height: "42px",
            borderRadius: "50%",
            border: selected ? "2px solid ".concat(color) : "2px solid #EEEFF2",
        },
    });
});
