var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { formattedTokenAmount } from "@broxus/js-utils";
import React from "react";
import { useParams } from "react-router-dom";
import { useCreateDirectExchange } from "@/api/wallet";
import { FailPopup } from "@/features/transfer/components/FailPopup";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { ExchangeType } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupTitle } from "@/shared/components/Popup/Title";
export var ExchangeModal = function (_a) {
    var _b;
    var isOpen = _a.isOpen, onClose = _a.onClose, data = _a.data, rateInfo = _a.rateInfo, toAmount = _a.toAmount, onSuccess = _a.onSuccess;
    var params = useParams();
    var _c = React.useState(false), isOpenSuccess = _c[0], setIsOpenSuccess = _c[1];
    var _d = React.useState(false), isOpenError = _d[0], setIsOpenError = _d[1];
    var _e = useCreateDirectExchange({
        onSuccess: function () {
            setIsOpenSuccess(true);
        },
        onError: function () {
            setIsOpenError(true);
        },
    }), mutateAsync = _e.mutateAsync, result = _e.data, isPendingPreExchange = _e.isPending;
    var exchange = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, mutateAsync({
                        dryRun: false,
                        data: data,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        isOpen && !isOpenSuccess && !isOpenError
            ? (React.createElement(Popup, { width: "450px", onClose: onClose },
                React.createElement(PopupHead, { onClose: onClose }),
                React.createElement(PopupBody, null,
                    React.createElement(PopupTitle, { type: "text32Bold" },
                        "You will pay ",
                        data.fromAmount,
                        " ",
                        data.fromCurrency,
                        " and get",
                        "  ",
                        React.createElement(Typography, { color: "deep-blue-500", type: "text32Bold" },
                            formattedTokenAmount(toAmount),
                            " ",
                            data.toCurrency)),
                    React.createElement(PopupContent, { justifyContent: "flex-end" },
                        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                            React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                                    React.createElement(Typography, { color: "black-500" }, rateInfo.label),
                                    React.createElement(Typography, null, rateInfo.value)),
                                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                                    React.createElement(Typography, { color: "black-500" }, "Processing time"),
                                    React.createElement(Typography, null, "Defined by bank")),
                                (data.feeAmount && data.feeCurrency) && (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                                    React.createElement(Typography, { color: "black-500" }, "Fees"),
                                    React.createElement(Typography, null, "".concat(formattedTokenAmount(data.feeAmount), " ").concat(data.feeCurrency.toUpperCase()))))),
                            React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                            React.createElement(Typography, { color: "black-500", type: "text12" }, "Please note that the final rate will be fixed, when you confirm the transaction"))),
                    React.createElement(Button, { width: "100%", loading: isPendingPreExchange, disabled: isPendingPreExchange, onClick: exchange }, "Continue"))))
            : null,
        isOpenSuccess && (React.createElement(SuccessPopup, { title: params.type === ExchangeType.Crypto
                ? "You've successfully exchanged the crypto"
                : "You've successfully exchanged your funds", address: (_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.id, amount: toAmount, currency: data.toCurrency, createdAt: result === null || result === void 0 ? void 0 : result.data.updatedAt, onClose: function () {
                onClose();
                onSuccess();
                setIsOpenSuccess(false);
            } })),
        isOpenError && (React.createElement(FailPopup, { onSubmit: exchange, onClose: function () {
                onClose();
                setIsOpenError(false);
            } }))));
};
