var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable react-hooks/exhaustive-deps */
import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import debounce from "lodash.debounce";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useNavigate, useParams, useSearchParams, } from "react-router-dom";
import { useCreateDirectExchange } from "@/api/wallet";
import { ExchangeModal } from "@/pages/ExchangePage/components/Modal";
import { ExchangeFormStore } from "@/pages/ExchangePage/stores/ExchangeFormStore";
import { ExchangeType, ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { Info } from "@/shared/components/Info";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { SectionAmount } from "@/shared/components/SectionAmount";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { TokenPopup } from "@/shared/components/TokenPopup";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { generateQueryPath } from "@/shared/utils/path";
export var Exchange = observer(function (_a) {
    var _b, _c, _d;
    var type = _a.type;
    var media = useMedia();
    var params = useParams();
    var fromCurrencyPopup = useToggler();
    var toCurrencyPopup = useToggler();
    var navigate = useNavigate();
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var currencyList = useStoreContext(CurrencyListStore);
    var accounts = useStoreContext(AccountListStore);
    var balances = useStore(BalanceListStore, currencyList);
    var form = useStore(ExchangeFormStore, balances);
    var _e = React.useState(false), isOpenModal = _e[0], setOpenModal = _e[1];
    var account = accounts.list[0];
    var accountId = account ? account.account : undefined;
    var error = !form.enoughFounds ? "Enter a smaller amount" : undefined;
    React.useEffect(function () {
        if (account) {
            balances.sync(account.account);
        }
    }, [balances, account, from, to]);
    React.useEffect(function () {
        runInAction(function () {
            form.account = account ? account.account : undefined;
            form.fromCurrency = from !== null && from !== void 0 ? from : undefined;
            form.toCurrency = to !== null && to !== void 0 ? to : undefined;
        });
    }, [account, from, to]);
    var _f = useCreateDirectExchange({}), mutateAsync = _f.mutateAsync, data = _f.data, isPendingPreExchange = _f.isPending, reset = _f.reset;
    var preExchangeInfo = data === null || data === void 0 ? void 0 : data.data;
    var preExchange = React.useCallback(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var amount, data_1, fee;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    amount = "";
                    if (!(accountId && from && to && !!Number(value))) return [3 /*break*/, 3];
                    return [4 /*yield*/, mutateAsync({
                            dryRun: true,
                            data: {
                                fromAccount: accountId,
                                toAccount: accountId,
                                fromCurrency: from,
                                toCurrency: to,
                                fromAmount: value,
                            },
                        })];
                case 1:
                    data_1 = _a.sent();
                    amount = data_1.data.toAmount;
                    if (!(data_1.data.feeAmount && data_1.data.feeCurrency)) return [3 /*break*/, 3];
                    return [4 /*yield*/, currencyList.convert(data_1.data.fromCurrency, data_1.data.toCurrency, data_1.data.feeAmount)];
                case 2:
                    fee = _a.sent();
                    amount = new BigNumber(amount).minus(fee !== null && fee !== void 0 ? fee : "0").toFixed();
                    _a.label = 3;
                case 3:
                    runInAction(function () {
                        form.toAmount = amount;
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [accountId, from, to, mutateAsync]);
    var onSuccess = function () {
        reset();
        form.reset();
        balances.sync(accountId);
    };
    var debounceExchange = React.useCallback(debounce(preExchange, 400), [
        preExchange,
    ]);
    var defaultAmount = form.fromAmount;
    React.useEffect(function () {
        debounceExchange(defaultAmount);
    }, [from, defaultAmount]);
    var isDisabledExchange = !!error || !Number(form.fromAmount);
    var fromField = (React.createElement(ExchangeField, { error: error, input: React.createElement(ExchangeInput, { decimals: from
                ? (_b = currencyList.bySlug[from.toLowerCase()]) === null || _b === void 0 ? void 0 : _b.decimals
                : undefined, invalid: !!error, postFix: from ? from.toUpperCase() : undefined, value: form.fromAmount, onChange: action(function (value) {
                form.fromAmount = value;
            }) }), label: "You send from bank", select: React.createElement(AccountSelect, { name: from !== null && from !== void 0 ? from : undefined, desc: form.fromBalance
                ? "".concat(formattedTokenAmount(form.fromBalance.balance), " ").concat(form.fromBalance.currency.toUpperCase())
                : undefined, icon: from ? React.createElement(TokenIcon, { symbol: from, size: 24 }) : undefined, onClick: fromCurrencyPopup.on }) }));
    var toField = (React.createElement(ExchangeField, { error: error, input: React.createElement(ExchangeInput, { decimals: to
                ? (_c = currencyList.bySlug[to.toLowerCase()]) === null || _c === void 0 ? void 0 : _c.decimals
                : undefined, invalid: !!error, postFix: to ? to.toUpperCase() : undefined, value: form.toAmount, onChange: action(function (value) {
                form.toAmount = value;
            }) }), label: "You get to bank", select: React.createElement(AccountSelect, { name: to !== null && to !== void 0 ? to : undefined, desc: form.toBalance
                ? "".concat(formattedTokenAmount(form.toBalance.balance), " ").concat(form.toBalance.currency.toUpperCase())
                : undefined, icon: to ? React.createElement(TokenIcon, { symbol: to, size: 24 }) : undefined, onClick: toCurrencyPopup.on }) }));
    var fromDecimals = (_d = currencyList.bySlug[preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromCurrency.toLowerCase()]) === null || _d === void 0 ? void 0 : _d.decimals;
    var rate = (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount) && (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
        ? BigNumber(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
            .div(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount)
            .toFixed(fromDecimals || 6)
        : undefined;
    var info = [
        {
            label: "Amount",
            value: from && (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
                ? "".concat(formattedTokenAmount(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount), " ").concat(from.toUpperCase())
                : "–",
        },
        {
            label: "Transaction fee",
            value: (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeAmount) && preExchangeInfo.feeCurrency
                ? "".concat(formattedTokenAmount(preExchangeInfo.feeAmount), " ").concat(preExchangeInfo.feeCurrency.toUpperCase())
                : "–",
        },
        {
            label: to ? "".concat(to, " rate") : "Rate",
            value: rate ? "".concat(rate, " ").concat(from) : "–",
        },
        {
            label: "Processing time",
            value: "Defined by bank",
        },
    ];
    return (React.createElement(React.Fragment, null,
        fromCurrencyPopup.enabled && (React.createElement(TokenPopup, { onClose: fromCurrencyPopup.off, onSelect: function (id) {
                fromCurrencyPopup.off();
                form.reset();
                navigate(generateQueryPath(ROUTES.exchange, {
                    type: params.type,
                }, {
                    from: id,
                    to: to,
                }), {
                    replace: true,
                });
            } })),
        toCurrencyPopup.enabled && (React.createElement(TokenPopup, { onClose: toCurrencyPopup.off, onSelect: function (id) {
                toCurrencyPopup.off();
                form.reset();
                navigate(generateQueryPath(ROUTES.exchange, {
                    type: params.type,
                }, {
                    from: from,
                    to: id,
                }), {
                    replace: true,
                });
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Typography, { type: "text32Bold", marginBottom: "60px", variant: "h2" }, "Exchange funds"),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                        fromField,
                        React.createElement(Button, { replace: true, variant: "Black", size: "xs", width: "50px", alignSelf: "center", LinkComponent: Link, tabIndex: -1, to: generateQueryPath(ROUTES.exchange, {
                                type: type === ExchangeType.Crypto
                                    ? ExchangeType.Fiat
                                    : ExchangeType.Crypto,
                            }, {
                                from: to,
                                to: from,
                            }) },
                            React.createElement(BaseIcon, { icon: "Swap", size: 14 })),
                        toField))), card: media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "50px" },
                React.createElement(Info, { data: info }),
                React.createElement(Box, { height: "1px", bgColor: "black-1000", width: "100%" }),
                React.createElement(SectionAmount, { amount: formattedTokenAmount(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount), currency: from !== null && from !== void 0 ? from : undefined }),
                React.createElement(Button, { width: "100%", onClick: function () { return setOpenModal(true); }, disabled: isPendingPreExchange || isDisabledExchange, loading: isPendingPreExchange }, "Exchange"))), side: !media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Info, { dark: false, data: __spreadArray(__spreadArray([], info, true), [
                        {
                            label: "Total + Fees",
                            value: (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount) && from
                                ? "".concat(formattedTokenAmount(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount), " ").concat(from)
                                : "–",
                        },
                    ], false) }),
                React.createElement(Button, { width: "100%", onClick: function () { return setOpenModal(true); }, disabled: isPendingPreExchange || isDisabledExchange, loading: isPendingPreExchange }, "Exchange"))) }),
        accountId && from && to && (React.createElement(ExchangeModal, { onSuccess: onSuccess, isOpen: isOpenModal, onClose: function () { return setOpenModal(false); }, data: {
                fromAccount: accountId,
                toAccount: accountId,
                fromCurrency: from,
                toCurrency: to,
                fromAmount: form.fromAmount,
                id: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.id,
                feeAmount: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeAmount,
                feeCurrency: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeCurrency,
            }, rateInfo: {
                label: "".concat(from, " rate"),
                value: rate ? "".concat(rate, " ").concat(from) : "–",
            }, toAmount: form.toAmount }))));
});
