var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { walletApi } from "./api";
import { TEXT_TYPE } from "./constants";
export var useGetHistoryQuery = function (_a) {
    var accountIds = _a.accountIds, page = _a.page;
    return useQuery({
        queryKey: __spreadArray(__spreadArray(["transfers-query"], accountIds, true), [page], false),
        queryFn: function () {
            return walletApi.balance.getBalanceLog({
                accountIds: accountIds || "",
                page: page,
                size: 10,
            }, {
                paramsSerializer: {
                    indexes: null,
                },
            });
        },
        staleTime: 100000,
        placeholderData: keepPreviousData,
        gcTime: 100000,
        enabled: !!accountIds.length,
        select: function (data) {
            return __assign(__assign({}, data.data), { data: data.data.data.map(function (item) { return (__assign(__assign({}, item), { operation: "".concat(TEXT_TYPE[item.reasonType]) })); }) });
        },
    });
};
