import { makeAutoObservable } from "mobx";
export var ReceiveCryptoStep;
(function (ReceiveCryptoStep) {
    ReceiveCryptoStep[ReceiveCryptoStep["ChooseCrypto"] = 0] = "ChooseCrypto";
    ReceiveCryptoStep[ReceiveCryptoStep["GetAddress"] = 1] = "GetAddress";
})(ReceiveCryptoStep || (ReceiveCryptoStep = {}));
var ReceiveCryptoStore = /** @class */ (function () {
    function ReceiveCryptoStore() {
        this.step = ReceiveCryptoStep.ChooseCrypto;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    Object.defineProperty(ReceiveCryptoStore.prototype, "availableBlockchains", {
        get: function () {
            var _a, _b;
            return (((_b = (_a = this.currency) === null || _a === void 0 ? void 0 : _a.tokens) === null || _b === void 0 ? void 0 : _b.map(function (token) { return token.blockchain; }).filter(function (blockchain) {
                return blockchain !== undefined;
            })) || []);
        },
        enumerable: false,
        configurable: true
    });
    return ReceiveCryptoStore;
}());
export { ReceiveCryptoStore };
