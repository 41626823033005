import * as React from "react";
import { BaseIcon, Typography } from "@/shared/components";
export var ApplyReferralCodeButton = function (_a) {
    var applied = _a.applied, onSubmit = _a.onSubmit;
    return (React.createElement(React.Fragment, null, applied ? (React.createElement(BaseIcon, { icon: "Success", size: 24 })) : (React.createElement("button", { type: "button", style: {
            backgroundColor: "#ECEEFF",
            padding: "8px 12px",
            borderRadius: "12px",
        }, onClick: onSubmit },
        React.createElement(Typography, { type: "text12", color: "deep-blue-500", whiteSpace: "nowrap" }, "Apply")))));
};
