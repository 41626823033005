import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Box, Typography } from "@/shared/components";
export var PromoCodeInput = function () {
    return (React.createElement(TextField, { fullWidth: true, placeholder: "Promo code", InputProps: {
            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", sx: {
                        width: "56px",
                        height: "36px",
                        backgroundColor: "#F6F7F8",
                        borderRadius: "12px",
                    } },
                    React.createElement(Typography, { type: "text12", style: {
                            color: "#0019FF",
                        } }, "Apply")))),
        } }));
};
