var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// eslint-disable-next-line max-classes-per-file
import { makeAutoObservable, runInAction } from "mobx";
import { walletApi } from "@/api/wallet/api";
var AddressItem = /** @class */ (function () {
    function AddressItem(data) {
        this.data = data;
    }
    Object.defineProperty(AddressItem.prototype, "isEmpty", {
        get: function () {
            return "unavailable" in this.data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "isCrypto", {
        get: function () {
            return "type" in this.data && this.data.type === "CRYPTO";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "isBank", {
        get: function () {
            return ("type" in this.data &&
                (this.data.type === "ACH" ||
                    this.data.type === "SWIFT" ||
                    this.data.type === "FEDWIRE"));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "type", {
        get: function () {
            return "type" in this.data ? this.data.type : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "accountHolderName", {
        get: function () {
            return "accountHolderName" in this.data
                ? this.data.accountHolderName
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "accountNumber", {
        get: function () {
            return "accountNumber" in this.data
                ? this.data.accountNumber
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "accountHolderAddress", {
        get: function () {
            return "accountHolderAddress" in this.data
                ? this.data.accountHolderAddress
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "bankName", {
        get: function () {
            return "bankName" in this.data ? this.data.bankName : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "bankAddress", {
        get: function () {
            return "bankAddress" in this.data ? this.data.bankName : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "address", {
        get: function () {
            return "address" in this.data ? this.data.address : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "accountId", {
        get: function () {
            return this.data.accountId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "accountRoutingNumber", {
        get: function () {
            return "accountRoutingNumber" in this.data
                ? this.data.accountRoutingNumber
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "memo", {
        get: function () {
            return "memo" in this.data ? this.data.memo : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressItem.prototype, "swiftBic", {
        get: function () {
            return "swiftBic" in this.data ? this.data.swiftBic : undefined;
        },
        enumerable: false,
        configurable: true
    });
    return AddressItem;
}());
var AddressList = /** @class */ (function () {
    function AddressList(data) {
        this.list = data.map(function (item) { return new AddressItem(item); });
    }
    Object.defineProperty(AddressList.prototype, "first", {
        get: function () {
            return this.list.at(0);
        },
        enumerable: false,
        configurable: true
    });
    return AddressList;
}());
var AddressListStore = /** @class */ (function () {
    function AddressListStore() {
        this.data = {};
        this.loading = {};
        makeAutoObservable(this, {}, { autoBind: true });
    }
    AddressListStore.prototype.sync = function () {
        var list = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            list[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var filtered;
            var _this = this;
            return __generator(this, function (_a) {
                filtered = list.filter(function (item) { return !!item; });
                // .filter((item) => !this.data[item!.account]?.[item!.currency!])
                // .filter((item) => !this.loading[item!.account]?.[item!.currency!])
                this.loading = filtered.reduce(function (acc, item) {
                    var _a, _b;
                    return (__assign(__assign({}, acc), (_a = {}, _a[item.account] = __assign(__assign({}, acc[item.account]), (_b = {}, _b[item.currency] = true, _b)), _a)));
                }, __assign({}, this.loading));
                filtered.forEach(function (item) {
                    walletApi.v2
                        .getAddresses({
                        accountId: item.account,
                        currency: item.currency,
                    })
                        .then(function (resp) {
                        runInAction(function () {
                            var _a, _b;
                            _this.data = __assign(__assign({}, _this.data), (_a = {}, _a[item.account] = __assign(__assign({}, _this.data[item.account]), (_b = {}, _b[item.currency] = new AddressList(resp.data), _b)), _a));
                        });
                    })
                        .catch(function (e) {
                        console.error(e);
                        runInAction(function () {
                            var _a, _b;
                            _this.data = __assign(__assign({}, _this.data), (_a = {}, _a[item.account] = __assign(__assign({}, _this.data[item.account]), (_b = {}, _b[item.currency] = new AddressList([
                                {
                                    accountId: item.account,
                                    unavailable: true,
                                },
                            ]), _b)), _a));
                        });
                    })
                        .finally(function () {
                        runInAction(function () {
                            var _a, _b;
                            _this.loading = __assign(__assign({}, _this.loading), (_a = {}, _a[item.account] = __assign(__assign({}, _this.loading[item.account]), (_b = {}, _b[item.currency] = false, _b)), _a));
                        });
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    return AddressListStore;
}());
export { AddressListStore };
