import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, FormControlInput } from "@/shared/components";
import { FormRow } from "@/shared/components/FormRow";
import { countries } from "@/shared/constants/countries";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { useStoreContext } from "@/shared/hooks/useStore";
import { OrderCardStore } from "@/pages/CardPage/store/OrderCardStore";
import { OrderCardStepHeader } from "@/pages/CardPage/components/OrderCardStepHeader";
export var ShippingAndBilling = observer(function () {
    var orderCard = useStoreContext(OrderCardStore);
    var countryOptions = countries.map(function (item) { return ({
        key: item.codeAlpha3,
        label: "".concat(item.flag, " ").concat(item.name),
    }); });
    var handleNextStepClick = function () {
        orderCard.nextStep();
    };
    var handleInputChange = function (field, value) {
        orderCard.setBillingAddress(field, value !== null && value !== void 0 ? value : "");
    };
    return (React.createElement(Box, { maxWidth: "800px", width: "100%", marginTop: "40px" },
        React.createElement(OrderCardStepHeader, { title: "Billing address", action: orderCard.previousStep, text: "Please use only Latin characters and digits" }),
        React.createElement(Box, { marginTop: "30px" },
            React.createElement(FormRow, null,
                React.createElement(FormControlInput, { value: orderCard.firstName, label: "First Name", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                        orderCard.firstName = e.target.value;
                    } }),
                React.createElement(FormControlInput, { value: orderCard.lastName, label: "Last Name", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                        orderCard.lastName = e.target.value;
                    } })),
            React.createElement(FormRow, { marginTop: "30px" },
                React.createElement(FormControlSelect, { value: orderCard.billingAddress.country, options: countryOptions, label: "Registered Country", errorPosition: "relative", onChange: function (_, option) {
                        return handleInputChange("country", option === null || option === void 0 ? void 0 : option.key);
                    } }),
                React.createElement(FormControlInput, { value: orderCard.billingAddress.city, label: "City", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                        return handleInputChange("city", e.target.value);
                    } })),
            React.createElement(FormRow, { marginTop: "30px" },
                React.createElement(FormControlInput, { value: orderCard.billingAddress.state, label: "State", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                        return handleInputChange("state", e.target.value);
                    } }),
                React.createElement(FormControlInput, { value: orderCard.billingAddress.postalCode, label: "Postal/ZIP code", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                        return handleInputChange("postalCode", e.target.value);
                    } })),
            React.createElement(FormRow, { marginTop: "30px" },
                React.createElement(FormControlInput, { value: orderCard.billingAddress.addressLine1, label: "Address line 1", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                        return handleInputChange("addressLine1", e.target.value);
                    } }),
                React.createElement(FormControlInput, { value: orderCard.billingAddress.addressLine2, label: "Address line 2", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                        return handleInputChange("addressLine2", e.target.value);
                    } }))),
        React.createElement(Box, { marginTop: "40px", display: "flex", justifyContent: "center" },
            React.createElement(Button, { onClick: handleNextStepClick, style: {
                    maxWidth: "400px",
                    width: "100%",
                    borderRadius: "16px",
                } }, "Next Step"))));
});
