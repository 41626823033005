import { observer } from "mobx-react-lite";
import * as React from "react";
import { SignupPageStore, SignupStep, } from "@/pages/SignupPage/stores/SignupPageStore";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { EmailForm } from "@/pages/SignupPage/components/EmailForm";
import { PasswordForm } from "@/pages/SignupPage/components/PasswordForm";
import { EmailCodeForm } from "@/pages/SignupPage/components/EmailCodeForm";
import { PhoneForm } from "@/pages/SignupPage/components/PhoneForm";
export var SignupPage = observer(function () {
    var auth = useStoreContext(AuthStore);
    var page = useStore(SignupPageStore, auth);
    var PageProvider = useProvider(SignupPageStore);
    return (React.createElement(PageProvider, { value: page }, page.step === SignupStep.Email ? (React.createElement(EmailForm, null)) : page.step === SignupStep.Password ? (React.createElement(PasswordForm, null)) : page.step === SignupStep.EmailCode ? (React.createElement(EmailCodeForm, null)) : page.step === SignupStep.Phone ? (React.createElement(PhoneForm, null)) : null));
});
