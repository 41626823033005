import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Navigate, Route, Routes } from "react-router-dom";
import { TwoFaModal } from "@/features/2fa-modal";
import { CreateRecipientModal } from "@/features/create-recipient";
import { AuthPage } from "@/pages/AuthPage";
import { SigninPage } from "@/pages/SigninPage";
import { SignupPage } from "@/pages/SignupPage";
import { PhonePage } from "@/pages/PhonePage";
import { PasswordResetPage } from "@/pages/PasswordResetPage";
import { UserPage } from "@/pages/UserPage";
import { ROUTES } from "@/routes";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { HomePage } from "@/pages/HomePage";
import { DepositPage } from "@/pages/DepositPage";
import { TransferPage } from "@/pages/TransferPage";
import { KYC0Page } from "@/pages/KYC0Page";
import { ExchangePage } from "@/pages/ExchangePage";
import { CardPage } from "@/pages/CardPage";
import { SubscriptionPage } from "@/pages/SubscriptionPage";
import { WalletsPage } from "@/pages/WalletsPage";
import { WalletPage } from "@/pages/WalletsPage/WalletPage";
// import { MfaStore } from "@/shared/stores/MfaStore";
// import { MFAForm } from "@/pages/SignupPage/components/MFAForm";
import { FiatsPage } from "@/pages/FiatsPage";
import { TransactionPage } from "@/pages/TransactionPage";
import { FiatPage } from "@/pages/FiatsPage/FiatPage";
export var RootInner = observer(function () {
    var auth = useStoreContext(AuthStore);
    return (React.createElement(React.Fragment, null,
        auth.initialized &&
            (auth.authorized ? (React.createElement(Routes, null,
                React.createElement(Route, { path: ROUTES.root, element: React.createElement(UserPage, null) },
                    React.createElement(Route, { path: ROUTES.root, element: React.createElement(HomePage, null) }),
                    React.createElement(Route, { path: ROUTES.card, element: React.createElement(CardPage, null) }),
                    React.createElement(Route, { path: ROUTES.subscription, element: React.createElement(SubscriptionPage, null) }),
                    React.createElement(Route, { path: ROUTES.walletList, element: React.createElement(WalletsPage, null) }),
                    React.createElement(Route, { path: ROUTES.fiatList, element: React.createElement(FiatsPage, null) }),
                    React.createElement(Route, { path: ROUTES.transactions, element: React.createElement(TransactionPage, null) }),
                    React.createElement(Route, { path: ROUTES.walletItem, element: React.createElement(WalletPage, null) }),
                    React.createElement(Route, { path: ROUTES.fiatItem, element: React.createElement(FiatPage, null) })),
                React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: generatePath(ROUTES.root) }) }),
                React.createElement(Route, { path: ROUTES.deposit, element: React.createElement(DepositPage, null) }),
                React.createElement(Route, { path: ROUTES.transfer, element: React.createElement(TransferPage, null) }),
                React.createElement(Route, { path: ROUTES.kyc, element: React.createElement(KYC0Page, null) }),
                React.createElement(Route, { path: ROUTES.exchange, element: React.createElement(ExchangePage, null) }))) : (React.createElement(Routes, null,
                React.createElement(Route, { path: ROUTES.root, element: React.createElement(AuthPage, null) },
                    React.createElement(Route, { path: ROUTES.root, element: auth.userInfo && !auth.userInfo.phone ? (React.createElement(PhonePage, null)) : (React.createElement(SignupPage, null)) }),
                    React.createElement(Route, { path: ROUTES.signin, element: auth.userInfo && !auth.userInfo.phone ? (React.createElement(PhonePage, null)) : (React.createElement(SigninPage, null)) }),
                    React.createElement(Route, { path: ROUTES.passwordReset, element: React.createElement(PasswordResetPage, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: generatePath(ROUTES.root) }) }))))),
        React.createElement(TwoFaModal, null),
        React.createElement(CreateRecipientModal, null)));
});
