import { observer } from "mobx-react-lite";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { AuthPageStore } from "@/pages/AuthPage/stores/AuthPageStore";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import { Box } from "@/shared/components";
export var AuthPage = observer(function () {
    var page = useStore(AuthPageStore);
    var PageProvider = useProvider(AuthPageStore);
    return (React.createElement(PageProvider, { value: page },
        React.createElement(Box, { display: "flex", height: "100vh", justifyContent: "center", alignItems: "flex-start", paddingTop: "65px" },
            React.createElement(Box, { maxWidth: "494px", width: "100%", maxHeight: "calc(100vh - 105px)", height: "100%", boxSizing: "border-box", bgColor: "general-white", borderRadius: "10px", marginX: "20px" },
                React.createElement(Outlet, null)))));
});
