var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubscriptionCard } from "./components/Card";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { Box, Typography } from "@/shared/components";
import { VerificationBanner } from "@/entities/Verification";
var options1 = [
    "3 bank accounts",
    "Unlimited account balance",
    "Multiple crypto wallets",
    "Unlimited wallet balance",
    "24x7 Customer support",
    "Instant on-ramp/off-ramp",
    "Crypto currency exchange fees from 2%",
    "Traditional currency exchange fees 3%",
    "Free same currency transfers inside the platform",
    "Domestic and international transfers",
    "EUR and GBP accounts (coming Q2)",
    "Visa payment card (coming soon)",
];
var options2 = [
    "7 bank accounts",
    "Unlimited account balance",
    "Multiple crypto wallets",
    "Unlimited wallet balance",
    "24x7 Customer support",
    "Instant on-ramp/off-ramp",
    React.createElement(React.Fragment, null,
        "Crypto currency exchange fees from",
        " ",
        React.createElement(Typography, { color: "general-green", type: "text14" }, "1.5%")),
    React.createElement(React.Fragment, null,
        "Traditional currency exchange fees",
        " ",
        React.createElement(Typography, { color: "general-green", type: "text14" }, "1.5%")),
    "Free same currency transfers inside the platform",
    "Domestic and international transfers",
    "EUR and GBP accounts (coming Q2)",
    "Visa payment card (coming soon)",
];
export var SubscriptionPage = observer(function () {
    var navigate = useNavigate();
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var data = useGetVerificationInfo().data;
    var status = data === null || data === void 0 ? void 0 : data.status;
    var selectPlanFn = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
            }
            catch (e) {
                console.error(e);
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(React.Fragment, null,
        status !== VerificationStatus.GREEN && (React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr", gap: "20px" },
            React.createElement(VerificationBanner, null))),
        status === VerificationStatus.GREEN && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                React.createElement(Typography, { textAlign: "center", type: "text32Bold" },
                    "Find the right plan for",
                    " ",
                    React.createElement(Typography, { type: "text32Bold", color: "deep-blue-500" }, "your financial goals")),
                React.createElement(Typography, { color: "black-500", textAlign: "center" }, "Get unlimited access")),
            React.createElement(Box, { display: "grid", gridTemplateColumns: "repeat(2,350px)", gap: "40px" },
                React.createElement(SubscriptionCard, { fee: "395", title: "Business Black", price: "195", options: options1, onSelect: function () { }, disabled: !data }),
                React.createElement(SubscriptionCard, { fee: "595", theme: "black", title: "Business Platinum", price: "395", options: options2, onSelect: function () { }, disabled: !data }))))));
});
