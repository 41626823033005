import { formattedTokenAmount } from "@broxus/js-utils";
import { styled } from "@mui/material/styles";
import BigNumber from "bignumber.js";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { SelectAssetPopup } from "@/pages/HomePage/components/TotalBalance/SelectAssetPopup";
import { DepositType, ExchangeType, ROUTES, TransferType } from "@/routes";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { Loader } from "@/shared/components/Loader";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { BalanceType, BalanceTypeStore, } from "@/shared/stores/BalanceTypeStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { UserCurrency, UserCurrencyStore, } from "@/shared/stores/UserCurrencyStore";
import { generateQueryPath } from "@/shared/utils/path";
export var TotalBalance = observer(function () {
    var popup = useToggler();
    var data = useGetVerificationInfo().data;
    var _a = useMedia(), tablet = _a.tablet, phone = _a.phone;
    var balanceType = useStoreContext(BalanceTypeStore);
    var accountList = useStoreContext(AccountListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var balanceList = useStore(BalanceListStore, currencyList);
    var userCurrency = useStoreContext(UserCurrencyStore);
    var isDisabled = (data === null || data === void 0 ? void 0 : data.status) !== VerificationStatus.GREEN;
    var account = accountList.checking;
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accountList.list.map(function (item) { return item.account; }));
    }, [balanceList, accountList]);
    var getBalance = function (currencies) {
        // TODO: if disabled
        if (!userCurrency.selected) {
            return undefined;
        }
        var currencyNamesFiltered = currencies
            .filter(function (currency) { return currency.eurPrice && currency.slug; })
            .map(function (currency) { return currency.slug; });
        var balances = currencyNamesFiltered.map(function (currency) { return balanceList.data[currency]; });
        var total = "0";
        // eslint-disable-next-line no-plusplus
        for (var i = 0; i < balances.length; i++) {
            var item = balances[i];
            if (!item) {
                return undefined;
            }
            var fromRate = currencyList.rates[item.currency.toLowerCase()];
            var toRate = currencyList.rates[userCurrency.selected];
            if (!(fromRate === null || fromRate === void 0 ? void 0 : fromRate.eurPrice) || !(toRate === null || toRate === void 0 ? void 0 : toRate.eurPrice)) {
                return undefined;
            }
            total = new BigNumber(total)
                .plus(new BigNumber(fromRate.eurPrice)
                .multipliedBy(item.balance)
                .dividedBy(toRate.eurPrice)
                .toFixed())
                .toFixed();
        }
        return total;
    };
    var balance = balanceType.type === BalanceType.All
        ? getBalance(currencyList.list)
        : balanceType.type === BalanceType.Crypto
            ? getBalance(currencyList.crypto)
            : balanceType.type === BalanceType.Fiat
                ? getBalance(currencyList.fiat)
                : undefined;
    return (React.createElement(React.Fragment, null,
        popup.enabled && React.createElement(SelectAssetPopup, { onClose: popup.off }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" },
                React.createElement(Typography, { type: "text16", color: "black-500" }, "Total balance"),
                React.createElement(Box, { display: "flex", alignItems: "center", gap: "15px" }, balance === undefined ? (React.createElement(Loader, { size: 24, containerSize: 47 })) : (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { type: "text36Bold" },
                        userCurrency.selected === UserCurrency.Eur
                            ? "€"
                            : userCurrency.selected ===
                                UserCurrency.Usd
                                ? "$"
                                : null,
                        formattedTokenAmount(balance)),
                    React.createElement(BaseIcon, { icon: "DropDown", containerSize: 32, bgColor: "black-100", borderRadius: "32px", onClick: popup.on, disabled: isDisabled }))))),
            React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                phone ? (React.createElement(Box, { display: "grid", alignItems: "center", gap: "10px", gridTemplateColumns: "1fr 1fr 1fr" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" },
                        React.createElement(Button, { padding: "10px", icon: "Plus", size: "icon", iconSize: 12, width: 46 }),
                        React.createElement(Typography, { type: "text14", color: "deep-blue-500" }, "Add funds")),
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" },
                        React.createElement(Button, { variant: "Gray", padding: "10px", icon: "Transfer", size: "icon", iconSize: 24, width: 46 }),
                        React.createElement(Typography, { type: "text14" }, "Transfer")),
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" },
                        React.createElement(Button, { variant: "Gray", padding: "10px", icon: "Exchange", size: "icon", iconSize: 24, width: 46 }),
                        React.createElement(Typography, { type: "text14" }, "Exchange")))) : (React.createElement(Box, { display: "grid", alignItems: "center", gap: "10px", gridTemplateColumns: "152px 152px 152px" },
                    React.createElement(Button, { size: "s", icon: "Deposit", iconSize: 24, disabled: isDisabled, LinkComponent: !isDisabled ? Link : undefined, to: generatePath(ROUTES.deposit, {
                            type: DepositType.Crypto,
                            id: account === null || account === void 0 ? void 0 : account.account,
                        }) }, "Add funds"),
                    React.createElement(Button, { size: "s", icon: "Transfer", iconSize: 24, variant: "Gray", disabled: isDisabled, LinkComponent: !isDisabled ? Link : undefined, to: generatePath(ROUTES.transfer, {
                            type: TransferType.Crypto,
                        }) }, "Transfer"),
                    React.createElement(Button, { size: "s", icon: "Exchange", iconSize: 24, variant: "Gray", disabled: isDisabled, LinkComponent: !isDisabled ? Link : undefined, to: generateQueryPath(ROUTES.exchange, {
                            type: ExchangeType.Crypto,
                        }) }, "Exchange"))),
                !tablet && (React.createElement(Box, { display: "flex", alignItems: "center", gap: "15px" },
                    React.createElement(StyledButton, { role: "button", icon: "DropDown", rotate: 90, bgColor: "general-white", borderRadius: "100%", padding: "10px", width: 45, height: 45, hoverBgColor: "black-200", borderWidth: "1px", borderStyle: "solid", className: "nav-accounts-prev" }),
                    React.createElement(StyledButton, { role: "button", width: 45, height: 45, borderRadius: "100%", icon: "DropDown", rotate: -90, bgColor: "general-white", padding: "10px", hoverBgColor: "black-200", borderWidth: "1px", borderStyle: "solid", className: "nav-accounts-next" })))))));
});
var StyledButton = styled(BaseIcon)(function (_a) {
    var theme = _a.theme;
    return ({
        border: "1px solid ".concat(theme.colors["general-white"]),
        "&.Icon.swiper-button-disabled": {
            cursor: "not-allowed",
            color: theme.colors["black-400"],
            border: "1px solid ".concat(theme.colors["black-100"]),
            background: "transparent",
        },
        transition: "all 0.5s ease",
        "&:hover:not(.swiper-button-disabled)": {
            background: theme.colors["black-200"],
        },
    });
});
