import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { useGetCompanyInfo, useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { Currency } from "@/pages/UserPage/components/Currency";
import { User } from "@/pages/UserPage/components/User";
import { ROUTES } from "@/routes";
import { Badge, BaseIcon, Box } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
export var Header = observer(function () {
    var _a, _b, _c;
    var media = useMedia();
    var menu = useStoreContext(MenuStore);
    var verification = useGetVerificationInfo();
    var companyInfo = useGetCompanyInfo();
    return (React.createElement(Box, { height: 72, display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px", gap: "22px" },
        media.phone && (React.createElement(Link, { to: generatePath(ROUTES.root) },
            React.createElement(BaseIcon, { icon: "LogoSmall", size: 36 }))),
        ((_a = companyInfo.data) === null || _a === void 0 ? void 0 : _a.companyName) ? (React.createElement(Box, { display: "flex", gap: "10px", alignItems: "center" },
            React.createElement(Box, { display: "flex", alignItems: media.phone ? "flex-start" : "center", gap: media.phone ? "0" : "10px", flexDirection: media.phone ? "column" : "row" }, ((_b = verification.data) === null || _b === void 0 ? void 0 : _b.status) ===
                VerificationStatus.GREEN ? (React.createElement(Badge, { color: "green" }, "Completed")) : ((_c = verification.data) === null || _c === void 0 ? void 0 : _c.status) ===
                VerificationStatus.REJECT ? (React.createElement(Badge, { color: "red" }, "Reject")) : (React.createElement(Badge, { color: "white" }, "In progress"))))) : (React.createElement(Box, null)),
        !media.phone && (React.createElement(Box, { display: "flex", alignItems: "center", gap: "22px" },
            React.createElement(Currency, null),
            React.createElement(User, null))),
        media.phone && (React.createElement(BaseIcon, { icon: "Burger", size: 24, onClick: menu.toggle }))));
});
