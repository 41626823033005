import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var CardSensitiveInfo = function (_a) {
    var sensitiveDetails = _a.sensitiveDetails;
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px" },
        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between", padding: "12px 16px", bgColor: "black-1000", borderRadius: "16px" },
            React.createElement(Typography, { type: "text20", color: "general-white", fontWeight: 400 }, sensitiveDetails.cardNumber),
            React.createElement(BaseIcon, { icon: "Copy", size: 20, color: "general-white" })),
        React.createElement(Box, { display: "flex", gap: "16px" },
            React.createElement(Box, { flex: "1", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "12px 16px", bgColor: "black-1000", borderRadius: "16px" },
                React.createElement(Typography, { type: "text20", color: "general-white", fontWeight: 400 }, sensitiveDetails.expDate),
                React.createElement(BaseIcon, { icon: "Copy", size: 20, color: "general-white" })),
            React.createElement(Box, { flex: "1", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "12px 16px", bgColor: "black-1000", borderRadius: "16px" },
                React.createElement(Typography, { type: "text20", color: "general-white", fontWeight: 400 }, sensitiveDetails.cvc),
                React.createElement(BaseIcon, { icon: "Copy", size: 20, color: "general-white" })))));
};
