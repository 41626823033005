var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupItem } from "@/shared/components/Popup/Item";
import { Typography } from "@/shared/components/Typography";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { TokenIcon } from "@/shared/components/TokenIcon";
export var TokenPopup = observer(function (_a) {
    var onSelect = _a.onSelect, onClose = _a.onClose;
    var currencyList = useStoreContext(CurrencyListStore);
    var currencyListFiltered = currencyList.crypto.filter(function (currency) { var _a; return (_a = currency.settings) === null || _a === void 0 ? void 0 : _a.walletEnabled; });
    var loaded = currencyList.crypto.every(function (currency) { return currency.id; });
    return (React.createElement(Popup, { onClose: onClose },
        React.createElement(PopupHead, { onClose: onClose }, "Select crypto"),
        React.createElement(Box, { height: "1px", bgColor: "black-50" }),
        React.createElement(Box, { position: "relative" },
            !loaded && (React.createElement(Loader, { size: 24, position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", zIndex: "100" })),
            React.createElement(Wrapper, { loaded: loaded }, currencyListFiltered.map(function (currency) { return (React.createElement(PopupItem, { key: currency.slug, onClick: function () { return onSelect(currency.slug); } },
                React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center" },
                    React.createElement(TokenIcon, { symbol: currency.slug, size: 36 }),
                    React.createElement(Typography, { type: "text16" }, currency.shortName)))); })))));
});
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n"], ["\n    position: relative;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n"])), function (props) { return (props.loaded ? 1 : 0); }, function (props) { return (props.loaded ? "unset" : "none"); });
var templateObject_1;
