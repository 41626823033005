import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import { observer } from "mobx-react-lite";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { UserCurrencyStore } from "@/shared/stores/UserCurrencyStore";
export var Amount = observer(function (_a) {
    var from = _a.from, to = _a.to, amount = _a.amount;
    var rates = useStoreContext(CurrencyListStore).rates;
    var userCurrency = useStoreContext(UserCurrencyStore);
    if (from && amount) {
        var toCurrency = (to || userCurrency.selected || "usd").toLowerCase();
        var fromRate = rates[from.toLowerCase()];
        var toRate = rates[toCurrency];
        if ((fromRate === null || fromRate === void 0 ? void 0 : fromRate.eurPrice) && (toRate === null || toRate === void 0 ? void 0 : toRate.eurPrice)) {
            var value = BigNumber(fromRate.eurPrice)
                .multipliedBy(amount)
                .dividedBy(toRate.eurPrice)
                .toFixed();
            return "".concat(formattedTokenAmount(value), " ").concat(toCurrency.toUpperCase());
        }
    }
    return "\u200b";
});
