import { ROUTES } from "@/routes";
export var HelperLinks = [];
export var ProfileLinks = [];
export var MenuLinks = [
    {
        label: "Dashboard",
        link: ROUTES.root,
        icon: "MenuDashboard",
    },
    {
        label: "Crypto assets",
        link: ROUTES.walletList,
        icon: "Crypto",
    },
    {
        label: "Fiat assets",
        link: ROUTES.fiatList,
        icon: "Bank",
    },
    {
        label: "Cards",
        link: ROUTES.card,
        icon: "Card",
    },
    {
        label: "Transactions history",
        link: ROUTES.transactions,
        icon: "MenuTransactions",
    },
    {
        label: "Tiers",
        link: ROUTES.subscription,
        icon: "Bank",
    },
];
