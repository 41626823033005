import { makeAutoObservable } from "mobx";
var KYC0PageStore = /** @class */ (function () {
    function KYC0PageStore() {
        this["firstName"] = "";
        this["lastName"] = "";
        this["country"] = "";
        this["postCode"] = "";
        makeAutoObservable(this, {}, { autoBind: true });
    }
    return KYC0PageStore;
}());
export { KYC0PageStore };
