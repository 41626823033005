var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// eslint-disable-next-line max-classes-per-file
import { makeAutoObservable, reaction } from "mobx";
import { walletApi } from "@/api/wallet/api";
import { CounterpartyType, } from "@/api/wallet/types";
import { DataSync } from "@/shared/stores/DataStore";
import { Reactions } from "@/shared/utils/reactions";
var Recipient = /** @class */ (function () {
    function Recipient(data) {
        this.data = data;
    }
    Object.defineProperty(Recipient.prototype, "accountNumber", {
        get: function () {
            return "accountNumber" in this.data ? this.data.accountNumber : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "iban", {
        get: function () {
            return "iban" in this.data ? this.data.iban : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "swiftBic", {
        get: function () {
            return "swiftBic" in this.data ? this.data.swiftBic : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "blockchainAddress", {
        get: function () {
            return "blockchainAddress" in this.data ? this.data.blockchainAddress : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "blockchain", {
        get: function () {
            return "blockchain" in this.data ? this.data.blockchain : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "currency", {
        get: function () {
            return "currency" in this.data ? this.data.currency : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "isCrypto", {
        get: function () {
            return this.data.type === CounterpartyType.CRYPTO;
        },
        enumerable: false,
        configurable: true
    });
    return Recipient;
}());
var RecipientListStore = /** @class */ (function () {
    function RecipientListStore(auth) {
        this.auth = auth;
        this.reactions = new Reactions();
        this.counterparty = new DataSync(walletApi.counterparty.getCounterparties.bind(walletApi));
        makeAutoObservable(this, {}, { autoBind: true });
    }
    RecipientListStore.prototype.init = function () {
        var _this = this;
        this.reactions.create(reaction(function () { return _this.auth.authorized; }, this.sync, {
            fireImmediately: true,
        }));
    };
    RecipientListStore.prototype.dispose = function () {
        this.reactions.destroy();
    };
    RecipientListStore.prototype.sync = function () {
        if (this.auth.authorized) {
            this.counterparty.sync({
                page: 0,
                size: 1000,
            });
        }
        else {
            this.counterparty.reset();
        }
    };
    Object.defineProperty(RecipientListStore.prototype, "list", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.counterparty.value) === null || _a === void 0 ? void 0 : _a.data.data.map(function (item) { return new Recipient(item); })) !== null && _b !== void 0 ? _b : [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RecipientListStore.prototype, "byId", {
        get: function () {
            return this.list
                .filter(function (item) { return !!item.data.id; })
                .reduce(function (acc, item) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[item.data.id] = item, _a)));
            }, {});
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RecipientListStore.prototype, "loading", {
        get: function () {
            return this.counterparty.loading;
        },
        enumerable: false,
        configurable: true
    });
    return RecipientListStore;
}());
export { RecipientListStore };
