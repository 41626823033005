var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { observer } from "mobx-react-lite";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, FormControlInput } from "@/shared/components";
import { FormLayout } from "@/shared/components/FormLayout";
import { FormRow } from "@/shared/components/FormRow";
import { useMedia } from "@/shared/hooks/useMedia";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { countries } from "@/shared/constants/countries";
export var KYC0Page = observer(function () {
    var media = useMedia();
    var form = useForm();
    var countryOptions = countries.map(function (item) { return ({
        key: item.codeAlpha2,
        label: "".concat(item.flag, " ").concat(item.name),
    }); });
    return (React.createElement(Box, { minHeight: "100vh", bgColor: "general-white", position: "relative" },
        React.createElement(Box, { paddingLeft: "max(20px, calc((100% - 1240px) / 2))", paddingRight: "max(20px, calc((100% - 1240px) / 2))", paddingTop: media.desktop ? "50px" : "20px", paddingBottom: media.desktop ? "50px" : "20px", margin: "0 auto" },
            React.createElement(Box, { position: "relative", zIndex: "0" },
                React.createElement(FormLayout, { title: "Personally Identifiable Information", desc: "" },
                    React.createElement(FormRow, null,
                        React.createElement(Controller, { control: form.control, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (React.createElement(FormControlInput, __assign({}, field, { label: "First Name", disabled: field.disabled, error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                            }, name: "firstName" }),
                        React.createElement(Controller, { control: form.control, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (React.createElement(FormControlInput, __assign({}, field, { label: "Last Name", disabled: field.disabled, error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                            }, name: "lastName" })),
                    React.createElement(FormRow, null,
                        React.createElement(Controller, { control: form.control, name: "residentialAddress.country", render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) {
                                        return field.onChange(v === null || v === void 0 ? void 0 : v.key);
                                    }, value: field.value, options: countryOptions, label: "Registered Country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                            } }),
                        React.createElement(Controller, { control: form.control, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (React.createElement(FormControlInput, __assign({}, field, { label: "City", disabled: field.disabled, error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                            }, name: "residentialAddress.town" })),
                    React.createElement(FormRow, null,
                        React.createElement(Controller, { control: form.control, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (React.createElement(FormControlInput, __assign({}, field, { label: "Street", disabled: field.disabled, error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                            }, name: "residentialAddress.street" }),
                        React.createElement(Controller, { control: form.control, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (React.createElement(FormControlInput, __assign({}, field, { label: "Post Code", disabled: field.disabled, error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                            }, name: "residentialAddress.postCode" })))))));
});
