var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupItem } from "@/shared/components/Popup/Item";
import { Typography } from "@/shared/components/Typography";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
export var BlockchainPopup = observer(function (_a) {
    var _b;
    var currencySlug = _a.currencySlug, onSelect = _a.onSelect, onClose = _a.onClose;
    var currencyList = useStoreContext(CurrencyListStore);
    var blockchainList = currencySlug
        ? (_b = currencyList.getBlockchains(currencySlug)) !== null && _b !== void 0 ? _b : []
        : [];
    var loaded = blockchainList.every(function (blockchain) { return blockchain && blockchain.id && blockchain.slug; });
    return (React.createElement(Popup, { onClose: onClose },
        React.createElement(PopupHead, { onClose: onClose }, "Select blockchain"),
        React.createElement(Box, { height: "1px", bgColor: "black-50" }),
        React.createElement(Box, { position: "relative" },
            !loaded && (React.createElement(Loader, { size: 24, position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", zIndex: "100" })),
            React.createElement(Wrapper, { loaded: loaded }, blockchainList.map(function (blockchain) {
                return blockchain && (React.createElement(PopupItem, { key: blockchain.slug, onClick: function () {
                        return onSelect(blockchain.slug);
                    } },
                    React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center" },
                        React.createElement(Typography, { type: "text16" }, blockchain.name))));
            })))));
});
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n"], ["\n    position: relative;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n"])), function (props) { return (props.loaded ? 1 : 0); }, function (props) { return (props.loaded ? "unset" : "none"); });
var List = styled("div")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"], ["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"])));
var templateObject_1, templateObject_2;
