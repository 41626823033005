import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { CardActionButton } from "@/pages/CardPage/components/CardDetailsModal/CardActionButton";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { CardDetailButton } from "@/pages/CardPage/components/CardDetailsModal/CardDetailButton";
import { CardFront } from "@/pages/CardPage/components/Card/CardFront";
import { CardBack } from "@/pages/CardPage/components/Card/CardBack";
export var CardDetailsModal = function (_a) {
    var card = _a.card, onClose = _a.onClose;
    var _b = React.useState(true), isFront = _b[0], setIsFront = _b[1];
    var toggleSide = function () {
        setIsFront(!isFront);
    };
    return (React.createElement(Popup, { onClose: onClose, width: "400px" },
        React.createElement(PopupHead, { onClose: onClose }, "General"),
        React.createElement(Box, { padding: "0px 25px 25px 25px" },
            isFront ? (React.createElement(CardFront, { card: card, toggleSide: toggleSide })) : (React.createElement(CardBack, { card: card, toggleSide: toggleSide })),
            React.createElement(Box, { display: "flex", justifyContent: "center", marginTop: "10px" },
                React.createElement(Box, { display: "flex", gap: "4px", alignItems: "center" },
                    React.createElement(BaseIcon, { icon: "Cloud", color: "black-500" }),
                    React.createElement(Typography, { type: "text16", color: "black-500" }, "Virtual"))),
            React.createElement(Box, { marginTop: "24px", display: "flex", justifyContent: "space-around" },
                React.createElement(CardActionButton, { icon: "PlusSmall", label: "Add funds" }),
                React.createElement(CardActionButton, { icon: "Freeze", label: "Freeze" }),
                React.createElement(CardActionButton, { icon: "Block", label: "Block" })),
            React.createElement(Box, { marginTop: "24px" },
                React.createElement(CardDetailButton, { icon: "Clock", title: "Card transactions", subtitle: "Nothing spent in July" }),
                React.createElement(CardDetailButton, { icon: "Limits", title: "Card limit", subtitle: "Limit is not set" }),
                React.createElement(CardDetailButton, { icon: "Settings", title: "Settings" })))));
};
