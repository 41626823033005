import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var OrderCardStepHeader = function (_a) {
    var title = _a.title, action = _a.action, text = _a.text;
    return (React.createElement(React.Fragment, null,
        " ",
        React.createElement(Box, { display: "flex", gap: "6px", alignItems: "center" },
            React.createElement(BaseIcon, { icon: "Back", size: 36, onClick: action, style: {
                    cursor: "pointer",
                } }),
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text28", fontWeight: 600 }, title))),
        text && (React.createElement(Typography, { type: "text12", fontWeight: 400, color: "black-500", marginLeft: "42px" }, text))));
};
