import React from "react";
import { Box, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { CardListItem } from "@/pages/CardPage/components/CardListItem";
import { CardDetailsModal } from "@/pages/CardPage/components/CardDetailsModal";
import { useToggler } from "@/shared/hooks/useToggler";
export var CardList = function (_a) {
    var cards = _a.cards;
    var tablet = useMedia().tablet;
    var popup = useToggler();
    var _b = React.useState(null), selectedCard = _b[0], setSelectedCard = _b[1];
    var onClick = function (card) {
        setSelectedCard(card);
        popup.on();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { bgColor: "general-white", borderRadius: "22px", marginTop: "20px", padding: tablet ? "30px" : "40px", marginBottom: "40px" },
            React.createElement(Typography, { color: "black-500" },
                "Virtual cards ",
                cards.length),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "8px", marginY: "10px" }, cards.map(function (card) { return (React.createElement(CardListItem, { card: card, onClick: onClick })); }))),
        popup.enabled && selectedCard && (React.createElement(CardDetailsModal, { card: selectedCard, onClose: popup.off }))));
};
