import { useQuery } from "@tanstack/react-query";
import { registrationApi } from "./api";
import { VerificationType } from "./types";
export var useGetVerificationInfo = function (enabled) {
    if (enabled === void 0) { enabled = true; }
    return useQuery({
        queryKey: ["registrationApi.verification.getVerificationStatus"],
        select: function (data) { return data.data; },
        queryFn: function () {
            return registrationApi.verification.getVerificationStatus({
                type: VerificationType.SUMSUB,
            });
        },
        retry: 0,
        enabled: enabled,
    });
};
