var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { BalanceItem } from "@/pages/HomePage/components/Balances/Item";
import { ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
export var Balances = observer(function () {
    var tablet = useMedia().tablet;
    var accounts = useStoreContext(AccountListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var balanceList = useStore(BalanceListStore, currencyList);
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.list.map(function (acc) { return acc.account; }));
    }, [balanceList, accounts.list]);
    var findCurrencyBalance = function (currency) {
        var _a, _b;
        return ((_b = (currency.slug && ((_a = balanceList.data[currency.slug]) === null || _a === void 0 ? void 0 : _a.balance))) !== null && _b !== void 0 ? _b : "0");
    };
    var loaded = accounts.crypto.every(function (item) { var _a; return (_a = balanceList.data[item.account]) === null || _a === void 0 ? void 0 : _a.balance; });
    if (accounts.list.length) {
        return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
            React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                React.createElement(Typography, { type: "text24" }, "Wallets"),
                React.createElement(Button, { variant: "Gray", LinkComponent: Link, to: generatePath(ROUTES.walletList), size: "s" }, "View all")),
            React.createElement(Box, { display: "flex", flexDirection: "column", bgColor: "general-white", borderRadius: "22px", padding: tablet ? "30px" : "40px", gap: tablet ? "30px" : "40px", marginBottom: "40px" },
                React.createElement(Box, { position: "relative" },
                    React.createElement(Grid, { tablet: tablet, visible: loaded }, currencyList.withWalletEnabled.map(function (currency) {
                        var _a;
                        var balanceAmount = findCurrencyBalance(currency);
                        return (React.createElement(BalanceItem, { key: currency.id, currency: currency.shortName, slug: currency.slug, id: accounts.list[0].account, amount: balanceAmount, type: (_a = currency.type) === null || _a === void 0 ? void 0 : _a.toLowerCase() }));
                    })),
                    !loaded && (React.createElement(Loader, { position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", size: 24 }))))));
    }
    return null;
});
var Grid = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: grid;\n    grid-template-columns: ", ";\n    gap: 20px 100px;\n    opacity: ", ";\n    transition: 0.2s ease;\n"], ["\n    display: grid;\n    grid-template-columns: ", ";\n    gap: 20px 100px;\n    opacity: ", ";\n    transition: 0.2s ease;\n"])), function (props) { return (props.tablet ? "1fr" : "1fr 1fr"); }, function (props) { return (props.visible ? 1 : 0); });
var templateObject_1;
