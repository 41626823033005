import React from "react";
import { observer } from "mobx-react-lite";
import { Popup } from "@/shared/components/Popup";
import { PopupCloseBtn } from "@/shared/components/Popup/CloseBtn";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PickCard } from "@/pages/CardPage/components/PickCard";
import { Stepper } from "@/shared/components/Stepper";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { OrderCardStore } from "@/pages/CardPage/store/OrderCardStore";
import { CardOffersStore } from "@/shared/stores/CarfOffersStore";
import { ShippingAndBilling } from "@/pages/CardPage/components/ShippingAndBilling";
import { OrderConfirmation } from "@/pages/CardPage/components/OrderConfirmation";
export var OrderCardPopup = observer(function (_a) {
    var onClose = _a.onClose;
    var orderCard = useStoreContext(OrderCardStore);
    var cardOffersStore = useStore(CardOffersStore);
    React.useEffect(function () {
        cardOffersStore.sync();
    }, [cardOffersStore]);
    var renderStep = function (step) {
        if (!cardOffersStore.list[0]) {
            return null;
        }
        switch (step) {
            case 1:
                return (React.createElement(PickCard, { onPopupClose: onClose, offer: cardOffersStore.list[0] }));
            case 3:
                return React.createElement(ShippingAndBilling, { onPopupClose: onClose });
            case 4:
                return React.createElement(OrderConfirmation, { onPopupClose: onClose });
            default:
                return null;
        }
    };
    return (React.createElement(Popup, { width: "100%" },
        React.createElement(PopupCloseBtn, { position: "absolute", top: "20px", right: "20px", onClick: onClose }),
        React.createElement(PopupBody, null,
            React.createElement(PopupContent, { height: "calc(100vh - 128px)", marginY: "32px", alignItems: "center" },
                React.createElement(Stepper, { steps: orderCard.availableSteps, activeStep: orderCard.currentStep }),
                renderStep(orderCard.currentStep)))));
});
