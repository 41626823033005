var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { UnknownError } from "@/shared/utils/error";
export var AuthScope;
(function (AuthScope) {
    AuthScope["ChangeEmail"] = "user_email:write";
    AuthScope["ChangePhone"] = "user_phone:write";
})(AuthScope || (AuthScope = {}));
var AuthStore = /** @class */ (function () {
    function AuthStore(tokenStorage, mfa) {
        this.tokenStorage = tokenStorage;
        this.mfa = mfa;
        this.initialized = false;
        this.userInfo = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    AuthStore.prototype.init = function () {
        this.sync();
    };
    AuthStore.prototype.sync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userInfo, accessToken, refreshToken, userInfoResp, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = this.tokenStorage.getAccessToken();
                        refreshToken = this.tokenStorage.getRefreshToken();
                        if (!(accessToken && refreshToken)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, registrationApi.user.getUserInfo()];
                    case 2:
                        userInfoResp = _a.sent();
                        userInfo = userInfoResp.data;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        runInAction(function () {
                            _this.initialized = true;
                            _this.userInfo = userInfo;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthStore.prototype.requestScope = function (scope) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, tokens;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, registrationApi.auth.getToken({
                            grant_type: "refresh_token",
                            refresh_token: this.tokenStorage.getRefreshToken(),
                            scope: scope,
                        })];
                    case 1:
                        resp = _a.sent();
                        if (!(resp.data.error === "mfa_required" && resp.data.mfa_token)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.mfa.start(resp.data.mfa_token, scope)];
                    case 2:
                        tokens = _a.sent();
                        this.tokenStorage.setTokens(tokens.accessToken, tokens.refreshToken);
                        return [3 /*break*/, 4];
                    case 3:
                        if (resp.data.access_token && resp.data.refresh_token) {
                            this.tokenStorage.setTokens(resp.data.access_token, resp.data.refresh_token);
                        }
                        else {
                            throw new Error(UnknownError.description);
                        }
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AuthStore.prototype.login = function (accessToken, refreshToken) {
        this.initialized = false;
        this.tokenStorage.setTokens(accessToken, refreshToken);
        this.mfa.checkIsOtpActive();
        this.sync();
    };
    AuthStore.prototype.logout = function () {
        this.initialized = false;
        this.tokenStorage.clean();
        this.sync();
    };
    AuthStore.prototype.activateMfa = function () {
        var mfaToken = this.tokenStorage.getAccessToken();
        if (mfaToken) {
            this.mfa.submitCode(mfaToken);
        }
    };
    Object.defineProperty(AuthStore.prototype, "authorized", {
        get: function () {
            var _a;
            return !!((_a = this.userInfo) === null || _a === void 0 ? void 0 : _a.phone) && !!this.userInfo.primaryEmail;
        },
        enumerable: false,
        configurable: true
    });
    return AuthStore;
}());
export { AuthStore };
