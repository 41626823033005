import { useQuery } from "@tanstack/react-query";
import { walletApi } from "./api";
export var useGetWalletAddress = function (accountId, currency, blockchain) {
    return useQuery({
        queryKey: ["useGetWalletAddress", accountId, blockchain, currency],
        queryFn: function () { return walletApi.v2.getAddress({
            accountId: accountId,
            blockchain: blockchain,
            addressIndex: 0,
            currency: currency,
        }); },
        select: function (data) {
            return data.data;
        },
        retry: 1,
    });
};
