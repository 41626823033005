import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
import { Box, Typography } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { useStoreContext } from "@/shared/hooks/useStore";
import { EmailCodeInput } from "@/pages/SignupPage/components/EmailCodeInput";
import { Link } from "@/shared/components/Link";
import { ROUTES } from "@/routes";
export var EmailCodeForm = observer(function () {
    var page = useStoreContext(SignupPageStore);
    return (React.createElement(AuthForm, { onBack: page.resetEmail, onSubmit: page.submitEmailCode, title: "5-digit code", desc: React.createElement(React.Fragment, null,
            "We emailed you a code to",
            " ",
            React.createElement(Typography, { color: "deep-blue-500" }, page.email),
            "."), content: React.createElement(Box, { display: "flex", alignItems: "center", flexDirection: "column" },
            React.createElement(EmailCodeInput, { onComplete: page.submitEmailCode }),
            React.createElement(Box, { marginTop: "24px", display: "flex", alignItems: "center", flexDirection: "column" },
                React.createElement(Box, null,
                    React.createElement(Link, { type: "text14", to: generatePath(ROUTES.signin) }, "Resend code")),
                React.createElement(Box, null,
                    React.createElement(Link, { type: "text14", to: generatePath(ROUTES.signin) }, "No code received?")))) }));
});
