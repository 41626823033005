import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button } from "@/shared/components";
import { DepositType, ExchangeType, ROUTES, TransferType } from "@/routes";
import { generateQueryPath } from "@/shared/utils/path";
export var AssetsActionPanel = function (_a) {
    var accountId = _a.accountId, currency = _a.currency;
    var params = useParams();
    return (React.createElement(Box, { display: "flex", gap: "10px" },
        React.createElement(Button, { icon: "Deposit", size: "s", LinkComponent: Link, to: generateQueryPath(ROUTES.deposit, {
                type: DepositType.Crypto,
                id: accountId !== null && accountId !== void 0 ? accountId : params.id,
            }, {
                currency: currency !== null && currency !== void 0 ? currency : "",
            }) }, "Add funds"),
        React.createElement(Button, { variant: "Gray", icon: "Transfer", size: "s", LinkComponent: Link, to: generateQueryPath(ROUTES.transfer, {
                type: TransferType.Crypto,
            }, {
                from: currency !== null && currency !== void 0 ? currency : "",
            }) }, "Transfer"),
        React.createElement(Button, { variant: "Gray", icon: "Exchange", size: "s", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                type: ExchangeType.Crypto,
            }, {
                from: currency !== null && currency !== void 0 ? currency : "",
            }) }, "Exchange")));
};
