var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { PopupItem } from "@/shared/components/Popup/Item";
import { Typography } from "@/shared/components/Typography";
import { UserPick } from "@/shared/components/UserPick";
import { sliceAddress } from "@/shared/utils/slice-address";
export var RecipientItem = function (_a) {
    var selected = _a.selected, address = _a.address, name = _a.name, onSelect = _a.onSelect;
    return (React.createElement(PopupItem, { onClick: onSelect },
        React.createElement(UserPick, { name: name }),
        React.createElement(Box, { display: "flex", flexDirection: "column" },
            React.createElement(Title, { type: "text14" },
                name,
                selected && React.createElement(BaseIcon, { size: 16, icon: "Success" })),
            address && (React.createElement(Typography, { type: "text14", color: "black-400" }, sliceAddress(address))))));
};
var Title = styled(Typography)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    gap: 5px;\n"], ["\n    display: flex;\n    align-items: center;\n    gap: 5px;\n"])));
var templateObject_1;
