import { formattedTokenAmount } from "@broxus/js-utils";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { DepositType, ExchangeType, ROUTES, TransferType } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { Amount } from "@/shared/components/Amount";
import { Loader } from "@/shared/components/Loader";
import { SquareBtn } from "@/shared/components/SquareBtn";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { useBack } from "@/shared/hooks/useBack";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { Transactions } from "@/widgets/Transactions";
import { AssetsActionPanel } from "@/shared/components/AssetsActionPanel";
export var FiatPage = observer(function () {
    var _a, _b;
    var params = useParams();
    var media = useMedia();
    var back = useBack();
    var accountList = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var addressList = useStoreContext(AddressListStore);
    var account = accountList.checking;
    var currency = params.currency;
    var balance = (_b = (currency && ((_a = balanceList.data[currency]) === null || _a === void 0 ? void 0 : _a.balance))) !== null && _b !== void 0 ? _b : "0";
    React.useEffect(function () {
        addressList.sync(account);
    }, [account, addressList]);
    React.useEffect(function () {
        if (!account) {
            accountList.sync();
        }
        else {
            balanceList.sync(account.account);
        }
    }, [account, params.id, balanceList, accountList]);
    return (React.createElement(Box, { gap: "40px", display: "flex", flexDirection: "column" },
        media.desktop ? (React.createElement(Button, { variant: "White", onClick: back, alignSelf: "flex-start", icon: "Back" }, "Back")) : (React.createElement(Box, { display: "flex", alignItems: "center", gap: "20px" },
            React.createElement(BaseIcon, { icon: "Back", onClick: back, bgColor: "general-white", containerSize: 45, borderRadius: "12px" }),
            React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Typography, { type: "text16" }, currency === null || currency === void 0 ? void 0 : currency.toUpperCase())))),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "23px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" },
                balance ? (React.createElement(Typography, { type: "text36Bold" },
                    formattedTokenAmount(balance),
                    " ", currency === null || currency === void 0 ? void 0 :
                    currency.toUpperCase())) : (React.createElement(Loader, { containerSize: 47, size: 24 })),
                React.createElement(Typography, { type: "text16", color: "black-400", fontWeight: 500 }, balance && currency ? (React.createElement(Amount, { from: currency, amount: balance })) : ("\u200b"))),
            media.desktop ? (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                React.createElement(Box, { display: "flex", gap: "15px", alignItems: "center" },
                    React.createElement(TokenIcon, { symbol: currency || "", size: 42 }),
                    React.createElement(Box, { display: "flex", flexDirection: "column" },
                        React.createElement(Typography, { type: "text16" }, currency === null || currency === void 0 ? void 0 : currency.toUpperCase()))),
                React.createElement(AssetsActionPanel, { accountId: account === null || account === void 0 ? void 0 : account.account, currency: currency }))) : (React.createElement(Box, { gap: "30px", display: "flex", alignItems: "center" },
                React.createElement(SquareBtn, { icon: "Deposit", type: "blue", to: generateQueryPath(ROUTES.deposit, {
                        type: DepositType.Crypto,
                        id: account === null || account === void 0 ? void 0 : account.account,
                    }, {
                        from: currency,
                    }) }, "Add funds"),
                React.createElement(SquareBtn, { icon: "Transfer", to: generateQueryPath(ROUTES.transfer, {
                        type: TransferType.Crypto,
                    }, {
                        from: currency,
                    }) }, "Transfer"),
                React.createElement(SquareBtn, { icon: "Exchange", to: generateQueryPath(ROUTES.exchange, {
                        type: ExchangeType.Crypto,
                    }, {
                        from: currency,
                    }) }, "Exchange")))),
        React.createElement(Box, { height: "1px", bgColor: "black-200", width: "100%" }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", mb: "20px" },
            React.createElement(Typography, { type: "text24", fontWeight: 500 }, "Transactions"),
            React.createElement(Transactions, { accountIds: (account === null || account === void 0 ? void 0 : account.account) ? [account === null || account === void 0 ? void 0 : account.account] : [] }))));
});
