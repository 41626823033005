import { formattedTokenAmount } from "@broxus/js-utils";
import React from "react";
import { generatePath } from "react-router-dom";
import { ROUTES } from "@/routes";
import { Box, Typography } from "@/shared/components";
import { Amount } from "@/shared/components/Amount";
import { Button } from "@/shared/components/Button";
import { Link } from "@/shared/components/Link";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { useMedia } from "@/shared/hooks/useMedia";
import { generateQueryPath } from "@/shared/utils/path";
export var BalanceItem = function (_a) {
    var currency = _a.currency, slug = _a.slug, id = _a.id, amount = _a.amount, type = _a.type;
    var phone = useMedia().phone;
    return (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between", gap: "15px" },
        React.createElement(Link, { to: generatePath(ROUTES.walletItem, { currency: currency }) },
            React.createElement(TokenIcon, { symbol: currency, size: phone ? 36 : 42 })),
        React.createElement(Box, { display: "flex", flexDirection: "column", flexGrow: "1" },
            React.createElement(Box, { display: "flex", alignItems: "center", gap: "4px" },
                React.createElement(Typography, { type: phone ? "text14" : "text20", color: "black-1200" }, formattedTokenAmount(amount)),
                React.createElement(Typography, { type: phone ? "text14" : "text20", color: "black-400" }, currency)),
            React.createElement(Typography, { type: phone ? "text12" : "text14", color: "black-400" },
                React.createElement(Amount, { from: slug, amount: amount || "0" }))),
        React.createElement(Button, { width: "auto", variant: "Bordered", size: "s", LinkComponent: Link, to: generateQueryPath(ROUTES.deposit, {
                type: type,
                id: id,
            }, {
                currency: slug,
            }) }, "Add funds")));
};
