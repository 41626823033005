import React from "react";
import { styled } from "@mui/material/styles";
import { StepConnector, stepConnectorClasses, StepLabel as StepLabelBase, } from "@mui/material";
export var StepIconRoot = styled("div")(function (_a) {
    var theme = _a.theme;
    return ({
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: theme.colors["deep-blue-500"],
    });
});
export var StepIcon = function () {
    return React.createElement(StepIconRoot, null);
};
export var StepLabel = styled(StepLabelBase)({
    marginTop: "0px",
    marginBottom: "0px",
});
export var StepConnectorRoot = styled(StepConnector)(function (_a) {
    var _b, _c, _d;
    var theme = _a.theme;
    return (_b = {},
        _b["&.".concat(stepConnectorClasses.alternativeLabel)] = {
            top: "12px",
        },
        _b["&.".concat(stepConnectorClasses.active)] = (_c = {},
            _c["& .".concat(stepConnectorClasses.line)] = {
                borderColor: "#784af4",
                borderTopWidth: 1,
            },
            _c),
        _b["&.".concat(stepConnectorClasses.completed)] = (_d = {},
            _d["& .".concat(stepConnectorClasses.line)] = {
                borderColor: "#784af4",
                borderTopWidth: 1,
            },
            _d),
        _b["& .".concat(stepConnectorClasses.line)] = {
            borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
            borderTopWidth: 1,
        },
        _b);
});
