var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var UnknownError = {
    description: "Unknown error",
};
export var NoTokensError = {
    description: "Tokens not founded",
};
export var RequiredFieldMessage = {
    description: "Field is required",
};
export var WrongFormatMessage = {
    description: "Incorrect format",
};
export var MfaRequired = {
    description: "Two factor authentication required",
};
export var parseApiError = function (e) {
    var _a, _b, _c, _d;
    if (((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) && e.response.data.errors.length > 0) {
        return e.response.data.errors;
    }
    if ((_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) {
        return [{ description: e.response.data.message }];
    }
    if (e === null || e === void 0 ? void 0 : e.message) {
        return [{ description: e.message }];
    }
    return [UnknownError];
};
export var groupErrorsByKey = function (errors) {
    return errors.reduce(function (acc, item) {
        var _a;
        var _b, _c;
        var key = (_b = item.key) !== null && _b !== void 0 ? _b : "unknown";
        var errs = (_c = acc[key]) !== null && _c !== void 0 ? _c : [];
        return __assign(__assign({}, acc), (_a = {}, _a[key] = errs.concat(item.description), _a));
    }, {});
};
