import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var CardListItem = function (_a) {
    var card = _a.card, onClick = _a.onClick;
    return (React.createElement(Box, { display: "flex", flexDirection: "row", gap: 2, alignItems: "center", onClick: function () { return onClick(card); } },
        React.createElement(BaseIcon, { icon: "ReapBlack", size: 36 }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: 1 },
            React.createElement(Typography, { type: "text14", style: { lineHeight: "16px" } },
                "Visa \u2022\u2022 ",
                card.last4),
            React.createElement(Typography, { type: "text12", color: "black-500", style: { lineHeight: "14px" } }, "Expires \u2022\u2022/\u2022\u2022"))));
};
