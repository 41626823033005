import * as React from "react";
import styles from "./index.module.scss";
import { Typography } from "@/shared/components/Typography";
export var PasswordHint = function (_a) {
    var _b = _a.match, match = _b === void 0 ? false : _b, _c = _a.showMatch, showMatch = _c === void 0 ? false : _c, uppercase = _a.uppercase, lowercase = _a.lowercase, special = _a.special, digit = _a.digit, length = _a.length;
    return (React.createElement("div", { className: styles.hint },
        showMatch && (React.createElement(Typography, { type: "text12", color: match ? "general-green" : "black-500" }, "Passwords must match")),
        React.createElement(Typography, { type: "text14", color: length ? "general-green" : "black-500" }, "8-32 characters long"),
        React.createElement(Typography, { type: "text14", color: uppercase && lowercase ? "general-green" : "black-500" }, "Lower and uppercase Latin letters"),
        React.createElement(Typography, { type: "text14", color: digit ? "general-green" : "black-500" }, "At least 1 number"),
        React.createElement(Typography, { type: "text14", color: special ? "general-green" : "black-500" }, "At least 1 symbol")));
};
