var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import React, { useState } from "react";
import { TransactionAmount } from "../TransactionAmount";
import { TransactionInfo } from "../TransactionInfo";
import { useGetHistoryQuery } from "@/api/wallet";
import { BalanceLogReasonType } from "@/api/wallet/types";
import { Box, Table, Typography } from "@/shared/components";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
export var Transactions = function (_a) {
    var onItems = _a.onItems, onSelectedItems = _a.onSelectedItems, _b = _a.accountIds, accountIds = _b === void 0 ? [] : _b, tableProps = _a.tableProps;
    var _c = useState(null), transaction = _c[0], setTransaction = _c[1];
    var _d = useState(0), page = _d[0], setPage = _d[1];
    var _e = useGetHistoryQuery({ accountIds: accountIds, page: page }), transfers = _e.data, isLoading = _e.isLoading, isFetching = _e.isFetching;
    var getBenicifiaryInfo = function (type, source) {
        if (type === BalanceLogReasonType.FIAT_WITHDRAWAL_REJECT ||
            type === BalanceLogReasonType.FIAT_WITHDRAWAL_REQUEST) {
            var info = JSON.parse(source).state.details;
            if (info.wType === "BANK_TRANSFER") {
                return (React.createElement(Typography, { whiteSpace: "normal", color: "black-400" },
                    info.beneficiaryName,
                    "/",
                    info.beneficiaryAccountNumber));
            }
        }
        return null;
    };
    React.useEffect(function () {
        var _a;
        onItems === null || onItems === void 0 ? void 0 : onItems((_a = transfers === null || transfers === void 0 ? void 0 : transfers.data) !== null && _a !== void 0 ? _a : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transfers]);
    var onPageChange = function (page) {
        setPage(page);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, __assign({ pagination: true, rows: (transfers === null || transfers === void 0 ? void 0 : transfers.data) || [], loading: isLoading || isFetching, columns: [
                __assign(__assign({}, GRID_CHECKBOX_SELECTION_COL_DEF), { minWidth: 70 }),
                {
                    field: "createdAt",
                    headerName: "Date",
                    sortable: true,
                    resizable: false,
                    flex: 1,
                    renderCell: function (_a) {
                        var value = _a.value;
                        return (React.createElement(Typography, { display: "flex", flexDirection: "column" },
                            formatDate(value, DATE_FORMAT.date),
                            React.createElement(Typography, { color: "black-400" }, formatDate(value, DATE_FORMAT.time))));
                    },
                },
                {
                    field: "operation",
                    headerName: "Operation",
                    resizable: false,
                    flex: 1.5,
                    renderCell: function (_a) {
                        var value = _a.value, row = _a.row;
                        return (React.createElement(Box, { display: "flex", flexDirection: "column" },
                            React.createElement(Typography, null, value),
                            getBenicifiaryInfo(row.reasonType, row.source)));
                    },
                },
                {
                    field: "change",
                    headerName: "Amount",
                    resizable: false,
                    flex: 1,
                    align: "right",
                    headerAlign: "right",
                    renderCell: function (_a) {
                        var row = _a.row;
                        return (React.createElement(TransactionAmount, { status: row.status, amount: row.change, currency: row.currency }));
                    },
                },
            ], getRowId: function (row) { return row.id; }, onRowClick: function (_a) {
                var row = _a.row;
                return setTransaction(row);
            }, initialState: {
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
                sorting: {
                    sortModel: [{ field: "createdAt", sort: "desc" }],
                },
            }, page: page, paginationMode: "server", totalPages: transfers === null || transfers === void 0 ? void 0 : transfers.totalPages, onPageChange: onPageChange, onRowSelectionModelChange: function (newRowSelectionModel) {
                var _a;
                if (!onSelectedItems)
                    return;
                var selectedItems = ((_a = transfers === null || transfers === void 0 ? void 0 : transfers.data) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
                    var id = _a.id;
                    return newRowSelectionModel.includes(id || "");
                })) || [];
                onSelectedItems === null || onSelectedItems === void 0 ? void 0 : onSelectedItems(selectedItems);
            } }, tableProps)),
        React.createElement(TransactionInfo, { transaction: transaction, onClose: function () { return setTransaction(null); } })));
};
