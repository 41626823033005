var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
export var StyledCheckbox = styled(Checkbox, {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var theme = _a.theme, $isHover = _a.$isHover;
    return (__assign({ background: "transparent", borderRadius: "6px", padding: "0", transition: "background 0.2s ease, opacity 0.2s ease", width: "15px", height: "15px", "& svg rect": {
            transition: "all 0.2s ease",
            stroke: theme.colors["black-100"],
        }, "&.MuiCheckbox-sizeMedium": {
            width: "20px",
            height: "20px",
        }, "&.Mui-disabled": {
            cursor: "not-allowed",
            pointerEvents: "all",
            background: "transparent",
            border: "1px solid ".concat(theme.colors["black-100"]),
            "svg path": {
                transition: "all 0.2s ease",
                stroke: theme.colors["black-100"],
            },
        }, "&:hover:not(.Mui-checked,.Mui-disabled)": {
            opacity: "1",
            "svg rect": {
                transition: "all 0.2s ease",
                stroke: theme.colors["deep-blue-500"],
            },
        }, "&.MuiCheckbox-indeterminate:not(.Mui-disabled,.Mui-checked) .SW-Icon": {
            opacity: "0",
            "svg path": {
                stroke: theme.colors["deep-blue-500"],
            },
        }, "&.MuiCheckbox-indeterminate:not(.Mui-disabled,.Mui-checked):hover .SW-Icon": {
            opacity: "1",
        } }, ($isHover && {
        "&:not(.Mui-checked,.Mui-disabled) .SW-Icon": {
            opacity: "1",
            "svg path": {
                stroke: theme.colors["deep-blue-500"],
            },
        },
        "&.Mui-checked:not(.Mui-disabled) .SW-Icon": {
            "svg path": {
                stroke: theme.colors["deep-blue-500"],
            },
        },
    })));
});
