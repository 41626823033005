import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { AuthPageStore } from "@/pages/AuthPage/stores/AuthPageStore";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
import { ROUTES } from "@/routes";
import { Box, FormControlCheckbox, FormControlInput, } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { Link } from "@/shared/components/Link";
import { Typography } from "@/shared/components/Typography";
import termsOfUse from "@/shared/files/terms_of_use.pdf";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange, onCheckbox } from "@/shared/utils/input";
export var EmailForm = observer(function () {
    var _a, _b;
    var page = useStoreContext(SignupPageStore);
    var auth = useStoreContext(AuthPageStore);
    var media = useMedia();
    return (React.createElement(AuthForm, { onBack: media.tablet ? auth.toggleForm : undefined, onSubmit: page.submitEmail, title: "Create an account", desc: React.createElement(React.Fragment, null,
            React.createElement(Typography, { type: "text14", color: "black-500" },
                "Already have an account?",
                " "),
            React.createElement(Link, { type: "text14", to: generatePath(ROUTES.signin) }, "Sign in")), content: React.createElement(React.Fragment, null,
            React.createElement(FormControlInput, { label: "Email", value: page.email, onChange: onChange(action(function (value) {
                    page.email = value;
                })), variantError: "alert", error: (_a = page.errorsByKey.email) === null || _a === void 0 ? void 0 : _a[0], disabled: page.loading }),
            React.createElement(Box, null,
                React.createElement(FormControlCheckbox, { checked: page.terms, onChange: onCheckbox(action(function (value) {
                        page.terms = value;
                    })), sx: {
                        alignItems: "center",
                    }, label: React.createElement(Typography, { type: "text14", color: "black-500" },
                        "I have read and agree with",
                        " ",
                        React.createElement("a", { href: termsOfUse }, "Terms and Conditions"),
                        ",") }),
                React.createElement(Button, { type: "submit", width: "100%", error: (_b = page.errorsByKey.unknown) === null || _b === void 0 ? void 0 : _b[0], disabled: !page.isEmailValid ||
                        !page.terms ||
                        page.loading, sx: { borderRadius: "16px", marginTop: "10px" } }, "Continue"))), footer: React.createElement(React.Fragment, null,
            React.createElement(Button, { width: "100%", variant: "Gray", icon: "Google", sx: { borderRadius: "16px" } }, "Sign up with Google"),
            React.createElement(Button, { width: "100%", variant: "Gray", icon: "Apple", sx: { borderRadius: "16px" } }, "Sign up with Apple")) }));
});
