var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { CreateRecipientStore } from "@/features/create-recipient/store/CreateRecipientStore";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupItem } from "@/shared/components/Popup/Item";
import { RecipientItem } from "@/shared/components/RecipientPopup/RecipientItem";
import { Typography } from "@/shared/components/Typography";
import { useStoreContext } from "@/shared/hooks/useStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
export var RecipientPopup = observer(function (_a) {
    var _b;
    var type = _a.type, onClose = _a.onClose, selectedId = _a.selectedId, onSelect = _a.onSelect, currency = _a.currency;
    var recipientList = useStoreContext(RecipientListStore);
    var recipients = (_b = recipientList.list) === null || _b === void 0 ? void 0 : _b.filter(function (item) {
        var _a;
        return currency
            ? ((_a = item.data.currency) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                currency.toLowerCase()
            : true;
    }).filter(function (item) { return (type ? item.data.type === type : true); });
    var store = useStoreContext(CreateRecipientStore);
    var openModal = function () { return store.setIsOpen(true); };
    return (React.createElement(Popup, { onClose: onClose },
        React.createElement(PopupHead, { onClose: onClose }, "Select recipient"),
        React.createElement(Box, { height: "1px", bgColor: "black-50" }),
        React.createElement(Box, { overflow: "hidden", borderRadius: "0 0 22px 22px" },
            React.createElement(List, null,
                React.createElement(PopupItem, { onClick: function () { return openModal(); } },
                    React.createElement(BaseIcon, { icon: "Plus", size: 14, containerSize: 36, borderRadius: "36px", bgColor: "light-blue", color: "deep-blue-500" }),
                    React.createElement(Typography, { type: "text14", color: "deep-blue-500" }, "Add new recipient")), recipients === null || recipients === void 0 ? void 0 :
                recipients.map(function (item) { return (React.createElement(RecipientItem, { key: item.data.id, selected: item.data.id === selectedId, name: item.data.profile.name, address: item.accountNumber, onSelect: function () {
                        return onSelect(item.data.id);
                    } })); })))));
});
var List = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"], ["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"])));
var templateObject_1;
