export var REGULATED_STATUS = [{
        key: "REGULATED",
        label: "Regulated",
    }, {
        key: "REGISTERED",
        label: "Registered",
    }, {
        key: "LICENSED",
        label: "Licensed",
    }, {
        key: "NONE",
        label: "None",
    }, {
        key: "NOT_REQUIRED",
        label: "Not required",
    }];
export var ACTIVITIES = [{
        key: "NONE",
        label: "None",
    }, {
        key: "ADULT_ENTERTAINMENT",
        label: "Adult entertainment",
    }, {
        key: "DRUGS",
        label: "Drugs",
    }, {
        key: "FIREARMS",
        label: "Firearms",
    }, {
        key: "GAMBLING",
        label: "Gambling",
    }, {
        key: "MARIJUANA",
        label: "Marijuana",
    }, {
        key: "TUMBLING",
        label: "Tumbling",
    }];
export var KYBEntityTypes = [{
        key: "C_CORP_PRIVATE",
        label: "C Corp private",
    }, {
        key: "C_CORP_PUBLIC",
        label: "C Corp public",
    }, {
        key: "HNWI",
        label: "HNWI",
    }, {
        key: "LLC",
        label: "LLC",
    }, {
        key: "LLP",
        label: "LLP",
    }, {
        key: "LP",
        label: "LP",
    }, {
        key: "S_CORP",
        label: "S Corp",
    }, {
        key: "SOLE_PROP",
        label: "Sole proprietorship",
    }, {
        key: "TRUST",
        label: "Trust",
    }, {
        key: "NON_PROFIT",
        label: "Nonprofit",
    }, {
        key: "OTHER",
        label: "Other",
    }];
export var KYBPrimarySourceOfFunds = [{
        key: "EMPLOYMENT",
        label: "Employment",
    }, {
        key: "SAVINGS",
        label: "Savings",
    }, {
        key: "WINNINGS",
        label: "Winnings",
    }, {
        key: "MARITAL",
        label: "Marital",
    }, {
        key: "REAL_ESTATE",
        label: "Real estate",
    }, {
        key: "TRUST",
        label: "Trust",
    }, {
        key: "INVESTMENT",
        label: "Investment",
    }, {
        key: "OTHER",
        label: "Other",
    }, {
        key: "COMPANY",
        label: "Company",
    }, {
        key: "COMPANY_CAPITAL",
        label: "Company capital",
    }, {
        key: "LOAN",
        label: "Loan",
    }, {
        key: "PRIVATE_CAPITAL",
        label: "Private capital",
    }, {
        key: "GRANT",
        label: "Grant",
    }];
export var KYBValues1 = [{
        key: "UPTO_10K",
        label: "Up to 10k",
    }, {
        key: "TEN_TO_100K",
        label: "10k to 100k",
    }, {
        key: "ONEHUNDREDK_TO_1M",
        label: "100k to 1m",
    }, {
        key: "ONE_TO_10M",
        label: "1M to 10M",
    }, {
        key: "TEN_TO_50M",
        label: "10M to 50M",
    }, {
        key: "FIFTY_TO_250M",
        label: "50M to 250M",
    }, {
        key: "MORE_THAN_250M",
        label: "More than 250m",
    }];
export var KYBValues2 = [{
        key: "UPTO_1K",
        label: "Up to 1K",
    }, {
        key: "ONE_TO_100K",
        label: "1K to 100k",
    }, {
        key: "ONEHUNDREDK_TO_1M",
        label: "100K to 1M",
    }, {
        key: "MILLION_TO_5M",
        label: "1M to 5M",
    }, {
        key: "MORE_THAN_5M",
        label: "More than 5m",
    }];
export var KYBValues3 = [{
        key: "UPTO_5",
        label: "Up to 5",
    }, {
        key: "FIVE_TO_10",
        label: "5 to 10",
    }, {
        key: "TEN_TO_25",
        label: "10 to 25",
    }, {
        key: "MORE_THAN_25",
        label: "More than 25",
    }];
export var USResidencyStatus = [{
        key: "US_CITIZEN",
        label: "US citizen",
    }, {
        key: "RESIDENT_ALIEN",
        label: "Resident alien",
    }, {
        key: "NON_RESIDENT_ALIEN",
        label: "Non resident alien",
    }];
export var IndividualTypes = [{
        key: "OFFICER",
        label: "Officer",
    }, {
        key: "BENEFICIAL_OWNER",
        label: "Beneficial owner",
    }, {
        key: "CONTROL_PERSON",
        label: "Control person",
    }, {
        key: "AUTHORIZED_PERSON",
        label: "Authorized person",
    }];
export var PaymentPurpose = [
    {
        key: "PERSONAL_ACCOUNT",
        label: "Personal account"
    },
    {
        key: "FAMILY",
        label: "Family"
    },
    {
        key: "INSURANCE",
        label: "Insurance"
    },
    {
        key: "INVESTMENT",
        label: "Investment"
    },
    {
        key: "REAL_ESTATE",
        label: "Real estate"
    },
    {
        key: "TUITION",
        label: "Tuition"
    },
    {
        key: "MEDICAL",
        label: "Medical"
    },
    {
        key: "TRAVEL",
        label: "Travel"
    },
    {
        key: "TRADE_TRANSACTIONS",
        label: "Trade transactions"
    },
    {
        key: "UTILITY",
        label: "Utility"
    },
    {
        key: "TAX",
        label: "Tax"
    },
    {
        key: "LOAN",
        label: "Loan"
    },
    {
        key: "BILLS",
        label: "Bills"
    },
    {
        key: "PAYROLL",
        label: "Payroll"
    },
    {
        key: "TELECOM",
        label: "Telecom"
    },
    {
        key: "INTELLECTUAL_PROPERTY",
        label: "Intellectual property"
    },
    {
        key: "OTHER_BUSINESS_SERVICES",
        label: "Other business services"
    },
    {
        key: "CHARITABLE_DONATIONS",
        label: "Charitable donations"
    },
    {
        key: "MORTGAGE",
        label: "Mortgage"
    },
    {
        key: "EXPENSES_REIMBURSEMENT",
        label: "Expenses reimbursement"
    },
    {
        key: "PROFESSIONAL_SERVICES",
        label: "Professional services"
    },
    {
        key: "MARKETING",
        label: "Marketing"
    },
    {
        key: "RENTAL_PROPERTY",
        label: "Rental property"
    },
    {
        key: "OTHER",
        label: "Other"
    },
];
