import React from "react";
import { observer } from "mobx-react-lite";
import { BaseIcon, Box, Button, FormControlCheckbox, Typography, } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import cardBlack from "@/shared/img/cards/reap-black.png";
import { PickCardAdvantage } from "@/pages/CardPage/components/PickCard/PickCardAdvantage";
import { OrderCardStepHeader } from "@/pages/CardPage/components/OrderCardStepHeader";
import { PickCardColor } from "@/pages/CardPage/components/PickCard/PickCardColor";
import { Link } from "@/shared/components/Link";
import mainCustomerAgreement from "@/shared/files/main_customer_agreement.pdf";
import { useStoreContext } from "@/shared/hooks/useStore";
import { OrderCardStore } from "@/pages/CardPage/store/OrderCardStore";
export var PickCard = observer(function (_a) {
    var offer = _a.offer, onPopupClose = _a.onPopupClose;
    var media = useMedia();
    var orderCard = useStoreContext(OrderCardStore);
    return (React.createElement(Box, { marginTop: "40px" },
        React.createElement(OrderCardStepHeader, { action: onPopupClose, title: "Pick your cards" }),
        React.createElement(Box, { marginTop: "24px", display: "grid", gap: "40px", width: "795px", maxWidth: "100%", gridTemplateColumns: media.phone ? "1fr" : "1fr 1fr" },
            React.createElement(Box, { width: "100%" },
                React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center" },
                    React.createElement(BaseIcon, { icon: "ReapBlack", width: 44, height: 36 }),
                    React.createElement(Typography, { type: "text24", fontWeight: 500 }, "Virtual Platinum")),
                React.createElement(Box, { display: "flex", justifyContent: "center", marginTop: "24px" },
                    React.createElement("img", { src: cardBlack, alt: "card-preview" }))),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "24px" },
                React.createElement(Box, { width: "100%", display: "flex", flexDirection: "column", gap: "16px" },
                    React.createElement(PickCardAdvantage, { icon: "Worldwide", title: "Use worldwide", text: "You can use the card anywhere in the world where VISA is accepted" }),
                    React.createElement(PickCardAdvantage, { icon: "Usd", title: "USD card", text: "Top up your card with selection of fiat and crypto currencies" }),
                    React.createElement(PickCardAdvantage, { icon: "Apple", title: "Apple Pay and Google Pay ready", text: "Empower your device by connecting your card for contactless payments" })),
                React.createElement(PickCardColor, { designs: offer.allowedCardDesigns }))),
        React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", gap: "8px", marginTop: "40px" },
            React.createElement(FormControlCheckbox, { checked: orderCard.termsAndConditions, onChange: function (_event, checked) {
                    orderCard.setTermsEndConditions(checked);
                } }),
            React.createElement(Typography, null,
                "I have read and agree with the",
                " ",
                React.createElement(Link, { href: mainCustomerAgreement, underline: "none", color: "textPrimary" }, "Terms and Conditions"))),
        React.createElement(Box, { display: "flex", justifyContent: "center", gap: "8px", marginTop: "16px" },
            React.createElement(Button, { style: { borderRadius: "16px", maxWidth: "400px" }, width: "100%", disabled: !orderCard.termsAndConditions, onClick: orderCard.nextStep },
                "Get card for ",
                offer.firstVirtualPriceAmount,
                " ",
                offer.virtualPriceCurrency))));
});
