import { makeAutoObservable } from "mobx";
var ExchangeFormStore = /** @class */ (function () {
    function ExchangeFormStore(balances) {
        this.balances = balances;
        this.account = undefined;
        this.fromCurrency = undefined;
        this.toCurrency = undefined;
        this.fromAmount = undefined;
        this.toAmount = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    Object.defineProperty(ExchangeFormStore.prototype, "fromBalance", {
        get: function () {
            return this.fromCurrency
                ? this.balances.data[this.fromCurrency]
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeFormStore.prototype, "toBalance", {
        get: function () {
            return this.toCurrency
                ? this.balances.data[this.toCurrency]
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeFormStore.prototype, "enoughFounds", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    ExchangeFormStore.prototype.getBalance = function (currency) {
        return this.account ? this.balances.data[currency] : undefined;
    };
    ExchangeFormStore.prototype.reset = function () {
        this.fromAmount = undefined;
        this.toAmount = undefined;
    };
    return ExchangeFormStore;
}());
export { ExchangeFormStore };
