var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { TwoFactorType, } from "@/api/registration/types";
import { CLIENT_ID } from "@/config";
import { TimerStore } from "@/shared/stores/TimerStore";
import { MfaRequired, parseApiError } from "@/shared/utils/error";
var MfaStore = /** @class */ (function () {
    function MfaStore() {
        this.visible = false;
        this.loading = false;
        this.list = [];
        this.isActive = false;
        this.errors = [];
        this.mfaToken = undefined;
        this.code = undefined;
        this.scope = undefined;
        this.timer = new TimerStore();
        this.authentication = undefined;
        this.challenge = undefined;
        this.barcodeUri = undefined;
        this.resolve = undefined;
        this.reject = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    MfaStore.prototype.reset = function () {
        var _a;
        this.mfaToken = undefined;
        this.challenge = undefined;
        this.authentication = undefined;
        this.code = undefined;
        this.visible = false;
        this.loading = false;
        this.errors = [];
        this.resolve = undefined;
        (_a = this.reject) === null || _a === void 0 ? void 0 : _a.call(this, new Error(MfaRequired.description));
        this.reject = undefined;
    };
    MfaStore.prototype.start = function (mfaToken, scope) {
        var _this = this;
        this.reset();
        this.mfaToken = mfaToken;
        this.scope = scope;
        this.visible = true;
        this.createChallenge();
        return new Promise(function (resolve, reject) {
            _this.resolve = resolve;
            _this.reject = reject;
        });
    };
    MfaStore.prototype.createChallenge = function (mfaToken) {
        return __awaiter(this, void 0, void 0, function () {
            var errors, authentication, challenge, activeAuthenticator, challengeResp, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (mfaToken) {
                            this.mfaToken = mfaToken;
                        }
                        errors = [];
                        authentication = undefined;
                        challenge = undefined;
                        runInAction(function () {
                            _this.loading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        if (!this.mfaToken) {
                            throw new Error("MFA token must be defined");
                        }
                        return [4 /*yield*/, this.getAuthenticatorList()];
                    case 2:
                        _a.sent();
                        activeAuthenticator = this.list.find(function (auth) {
                            return auth.authenticator_type === TwoFactorType.Otp &&
                                auth.active;
                        });
                        if (!activeAuthenticator) {
                            throw new Error("No active authenticator found");
                        }
                        return [4 /*yield*/, registrationApi.mfa.createChallenge({
                                authenticator_id: activeAuthenticator.id,
                                challenge_type: TwoFactorType.Otp,
                                mfa_token: this.mfaToken,
                            })];
                    case 3:
                        challengeResp = _a.sent();
                        authentication = activeAuthenticator;
                        challenge = challengeResp.data;
                        this.timer.start();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        console.error(e_1);
                        errors = parseApiError(e_1);
                        return [3 /*break*/, 5];
                    case 5:
                        runInAction(function () {
                            _this.challenge = challenge;
                            _this.authentication = authentication;
                            _this.loading = false;
                            _this.errors = errors;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MfaStore.prototype.associateMFA = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errors, loading, barcodeUri, MFAData, e_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        errors = [];
                        loading = true;
                        barcodeUri = undefined;
                        runInAction(function () {
                            _this.loading = loading;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, registrationApi.mfa.createTwoFactorAuthentication({
                                type: TwoFactorType.Otp,
                            })];
                    case 2:
                        MFAData = (_a.sent()).data;
                        barcodeUri = MFAData.barcodeUri;
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        errors = parseApiError(e_2);
                        loading = false;
                        return [3 /*break*/, 4];
                    case 4:
                        runInAction(function () {
                            _this.barcodeUri = barcodeUri;
                            _this.errors = errors;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MfaStore.prototype.checkIsOtpActive = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errors, loading, isActive, e_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        errors = [];
                        loading = true;
                        isActive = false;
                        runInAction(function () {
                            _this.loading = loading;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getAuthenticatorList()];
                    case 2:
                        _a.sent();
                        isActive = this.list.some(function (twoFactor) {
                            return twoFactor.authenticator_type === "otp" && twoFactor.active;
                        });
                        loading = false;
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        errors = parseApiError(e_3);
                        this.loading = false;
                        return [3 /*break*/, 4];
                    case 4:
                        runInAction(function () {
                            _this.isActive = isActive;
                            _this.errors = errors;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MfaStore.prototype.getAuthenticatorList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errors, loading, list, authenticatorList, e_4;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        errors = [];
                        loading = true;
                        list = [];
                        runInAction(function () {
                            _this.loading = loading;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, registrationApi.mfa.getListOfTwoFactorAuthentications()];
                    case 2:
                        authenticatorList = (_a.sent()).data;
                        list = authenticatorList;
                        loading = false;
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _a.sent();
                        console.error(e_4);
                        errors = parseApiError(e_4);
                        this.loading = false;
                        return [3 /*break*/, 4];
                    case 4:
                        runInAction(function () {
                            _this.list = list;
                            _this.errors = errors;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MfaStore.prototype.submitCode = function (mfaToken) {
        return __awaiter(this, void 0, void 0, function () {
            var errors, loading, resp, e_5;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (mfaToken) {
                            this.mfaToken = mfaToken;
                        }
                        errors = [];
                        loading = true;
                        runInAction(function () {
                            _this.loading = loading;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, , 7]);
                        if (!this.mfaToken) {
                            throw new Error("MfaToken must be defined");
                        }
                        if (!this.code) {
                            throw new Error("Code must be defined");
                        }
                        return [4 /*yield*/, registrationApi.auth.getToken({
                                grant_type: "mfa-otp",
                                client_id: CLIENT_ID,
                                mfaToken: this.mfaToken,
                                otp: this.code,
                            })];
                    case 2:
                        resp = _b.sent();
                        if (!resp.data.access_token) return [3 /*break*/, 4];
                        (_a = this.resolve) === null || _a === void 0 ? void 0 : _a.call(this, {
                            accessToken: resp.data.access_token,
                            refreshToken: resp.data.refresh_token,
                        });
                        return [4 /*yield*/, this.checkIsOtpActive()];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4: throw new Error("Access token not founded");
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_5 = _b.sent();
                        console.error(e_5);
                        errors = parseApiError(e_5);
                        this.loading = false;
                        return [3 /*break*/, 7];
                    case 7:
                        runInAction(function () {
                            _this.errors = errors;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return MfaStore;
}());
export { MfaStore };
