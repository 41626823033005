var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { useMedia } from "@/shared/hooks/useMedia";
export var AccountTable = observer(function (_a) {
    var loaded = _a.loaded, children = _a.children;
    var media = useMedia();
    return (React.createElement(Box, { bgColor: "general-white", borderRadius: "22px", padding: media.desktop ? "40px" : "20px 0" },
        React.createElement(Box, { position: "relative" },
            !loaded && (React.createElement(Loader, { position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", size: 24 })),
            React.createElement(List, { loaded: !!loaded, desktop: media.desktop }, children))));
});
var List = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    gap: 20px;\n    display: grid;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n    grid-template-columns: ", ";\n"], ["\n    gap: 20px;\n    display: grid;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n    grid-template-columns: ", ";\n"])), function (props) { return (props.loaded ? 1 : 0); }, function (props) { return (props.loaded ? "unset" : "none"); }, function (props) {
    return props.desktop ? "1fr 1fr" : "minmax(0, 1fr)";
});
var templateObject_1;
