import * as React from "react";
import { generatePath, Navigate, useParams } from "react-router-dom";
import { DepositCrypto } from "@/pages/DepositPage/components/DepositCrypto";
import { DepositFiat } from "@/pages/DepositPage/components/DepositFiat";
import { DepositType, ROUTES } from "@/routes";
export var DepositPage = function () {
    var params = useParams();
    switch (params.type) {
        case DepositType.Crypto:
            return React.createElement(DepositCrypto, null);
        case DepositType.Fiat:
            return React.createElement(DepositFiat, null);
        default:
            return (React.createElement(Navigate, { to: generatePath(ROUTES.deposit, {
                    type: DepositType.Crypto,
                }), replace: true }));
    }
};
