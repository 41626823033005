import React from "react";
import { Box, Stepper as StepperBase, Step as StepBase } from "@mui/material";
import { StepConnectorRoot, StepIconRoot, StepLabel, } from "@/shared/components/Stepper/styled";
import { Typography } from "@/shared/components";
export var Stepper = function (_a) {
    var steps = _a.steps, activeStep = _a.activeStep;
    return (React.createElement(Box, { width: "100%", maxWidth: "800px" },
        React.createElement(StepperBase, { alternativeLabel: true, connector: React.createElement(StepConnectorRoot, null), activeStep: activeStep }, steps.map(function (step) { return (React.createElement(StepBase, { key: step.label, sx: { paddingLeft: 0, paddingRight: 0, width: "100%" } },
            React.createElement(StepLabel, { StepIconComponent: StepIconRoot },
                React.createElement(Typography, { type: "text12", color: "black-500" }, step.label)))); }))));
};
