import { observer } from "mobx-react-lite";
import * as React from "react";
import { SigninPageStore, SigninStep, } from "@/pages/SigninPage/stores/SigninPageStore";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { EmailForm } from "@/pages/SigninPage/components/EmailForm";
import { PasswordForm } from "@/pages/SigninPage/components/PasswordForm";
import { VerificationForm } from "@/pages/SigninPage/components/VerificationForm";
export var SigninPage = observer(function () {
    var auth = useStoreContext(AuthStore);
    var page = useStore(SigninPageStore, auth);
    var PageProvider = useProvider(SigninPageStore);
    return (React.createElement(PageProvider, { value: page }, page.step === SigninStep.Email ? (React.createElement(EmailForm, null)) : page.step === SigninStep.Password ? (React.createElement(PasswordForm, null)) : page.step === SigninStep.Verification ? (React.createElement(VerificationForm, null)) : null));
});
