var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import { TextField } from "@mui/material";
import { Box } from "@/shared/components";
import { observer } from "mobx-react-lite";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
export var EmailCodeInput = observer(function (_a) {
    var _b = _a.length, length = _b === void 0 ? 5 : _b, onComplete = _a.onComplete;
    var page = useStoreContext(SignupPageStore);
    var _c = React.useState(Array(length).fill("")), values = _c[0], setValues = _c[1];
    var _d = React.useState(Array(length).fill(false)), hiddenValues = _d[0], setHiddenValues = _d[1];
    var inputsRef = React.useRef([]);
    var handleChange = function (e, index) {
        var _a;
        var value = e.target.value;
        if (!/[0-9]/.test(value))
            return;
        var newValues = __spreadArray([], values, true);
        newValues[index] = value;
        setValues(newValues);
        page.updateEmailCode(newValues.join(""));
        if (index < length - 1 && value) {
            (_a = inputsRef.current[index + 1]) === null || _a === void 0 ? void 0 : _a.focus();
        }
        setTimeout(function () {
            var newHiddenValues = __spreadArray([], hiddenValues, true);
            // eslint-disable-next-line no-plusplus
            for (var i = 0; i <= index; i++) {
                newHiddenValues[i] = true;
            }
            setHiddenValues(newHiddenValues);
        }, 600);
        if (newValues.every(function (val) { return val !== ""; })) {
            onComplete(newValues.join(""));
        }
    };
    React.useEffect(function () {
        if (page.errors.length > 0) {
            setTimeout(function () {
                var _a;
                page.updateEmailCode("");
                setValues(Array(length).fill(""));
                setHiddenValues(Array(length).fill(false));
                (_a = inputsRef.current[0]) === null || _a === void 0 ? void 0 : _a.focus();
            }, 750);
        }
    }, [length, page, page.errors]);
    var handleKeyDown = function (e, index) {
        var _a;
        if (e.key === "Backspace") {
            e.preventDefault();
            var newValues = __spreadArray([], values, true);
            var newHiddenValues = __spreadArray([], hiddenValues, true);
            if (values[index] !== "") {
                newValues[index] = "";
                newHiddenValues[index] = false;
            }
            else if (index > 0) {
                (_a = inputsRef.current[index - 1]) === null || _a === void 0 ? void 0 : _a.focus();
                newValues[index - 1] = "";
                newHiddenValues[index - 1] = false;
            }
            setValues(newValues);
            setHiddenValues(newHiddenValues);
            page.updateEmailCode(newValues.join(""));
        }
    };
    return (React.createElement(Box, { display: "flex", gap: "10px" }, Array.from({ length: length }).map(function (_, index) { return (React.createElement(TextField, { key: index, variant: "outlined", sx: {
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            width: "38px",
            height: "56px",
        }, inputProps: {
            maxLength: 1,
            style: {
                textAlign: "center",
            },
        }, inputRef: function (el) { return (inputsRef.current[index] = el); }, value: hiddenValues[index] ? "•" : values[index], onChange: function (e) { return handleChange(e, index); }, onKeyDown: function (e) { return handleKeyDown(e, index); }, error: page.errors.length && values[index] === ""
            ? true
            : undefined })); })));
});
